import React from 'react';
import * as cheerio from 'cheerio';
import * as quotedPrintable from 'quoted-printable';
import qs from 'qs';
import axios from 'axios';
import _ from 'lodash';
import DEFAULT_PLANS from '@mocks/plans.json';
import SMS_PLANS_ADDONS from '@mocks/addons.json';
import testPlans from '@mocks/testPlans.json';

import { IMedia, ILocalesModel, IPagination } from 'types/strapi';
import {
  IPlan,
  IPlanFeature,
  IPlanFeatureDetail,
  ISMSPlan,
  ISMSPlanDetail,
  PDFLicense,
} from 'types/pricing';
import { IToken } from 'types/common';
import { ParsedDetails } from 'types/api';
import Logger from '@utils/logger/client';
import { toast } from 'react-toastify';
import AdjustablePriceComponent from '@components/pricing/compareplandescription/AdjustablePriceComponent';
import { SelectedPriceOption } from '@context/globalState';
import { CompareData } from 'types/strapi/language/pricing';
import { MEDIA_URL } from '../config';
import Toggle from '../components/pricing/customtablesharecomponents/toggle';
import NumberInput from '../components/pricing/customtablesharecomponents/number';
import { validateUser } from './api';

export const defaultCurrency = {
  name: 'USD',
  symbol: '$',
  code: 'USD',
};

export const filterByOptions = [
  {
    text: 'All',
    icon: () => (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_13826_156219)">
          <mask
            id="mask0_13826_156219"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="6"
            y="6"
            width="24"
            height="24"
          >
            <rect x="6" y="6" width="24" height="24" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_13826_156219)">
            <rect
              x="10"
              y="11"
              width="15"
              height="11.5"
              rx="1"
              fill="#E5E7EB"
            />
            <path
              d="M27.7305 11.5324V14.1737C27.2536 13.8869 26.7294 13.6818 26.1808 13.5676C26.0917 13.5494 26.0025 13.5333 25.9125 13.5196V11.5324C25.9125 11.3003 25.8654 11.0705 25.7738 10.8561C25.6822 10.6417 25.548 10.4469 25.3788 10.2829C25.2096 10.1189 25.0087 9.98885 24.7877 9.90022C24.5667 9.81159 24.3298 9.76612 24.0907 9.76639H10.6398C10.1572 9.76639 9.69433 9.95245 9.35307 10.2836C9.01182 10.6148 8.8201 11.064 8.8201 11.5324V21.762C8.8201 21.994 8.86717 22.2236 8.95862 22.4379C9.05006 22.6521 9.1841 22.8468 9.35307 23.0108C9.52204 23.1748 9.72264 23.3048 9.94342 23.3936C10.1642 23.4823 10.4008 23.528 10.6398 23.528H18.403V24.4665C18.4029 24.744 18.4278 25.021 18.4773 25.2944H18.0614V26.2329H19.0284C19.2697 26.2329 19.5011 26.3259 19.6717 26.4915C19.8423 26.6571 19.9382 26.8817 19.9382 27.1159C19.9382 27.35 19.8423 27.5746 19.6717 27.7402C19.5011 27.9058 19.2697 27.9988 19.0284 27.9988H15.2747C15.0334 27.9988 14.802 27.9058 14.6313 27.7402C14.4607 27.5746 14.3649 27.35 14.3649 27.1159C14.3649 26.8817 14.4607 26.6571 14.6313 26.4915C14.802 26.3259 15.0334 26.2329 15.2747 26.2329H16.2421V25.2944H10.6398C9.67448 25.2943 8.74876 24.9221 8.06619 24.2597C7.38362 23.5973 7.00011 22.6989 7 21.762V11.5324C7.00011 10.5956 7.38362 9.69715 8.06619 9.03473C8.74876 8.3723 9.67448 8.00011 10.6398 8H24.0907C25.056 8.00011 25.9817 8.3723 26.6643 9.03473C27.3468 9.69715 27.7304 10.5956 27.7305 11.5324Z"
              fill="#E5E7EB"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.9108 14.7857C26.6019 14.93 27.2345 15.2671 27.7309 15.7556C28.4063 16.416 28.7846 17.309 28.7832 18.2394V24.4704C28.7831 25.4072 28.3996 26.3056 27.717 26.968C27.0345 27.6304 26.1088 28.0026 25.1435 28.0027H23.3234C22.8453 28.0032 22.3718 27.9121 21.93 27.7347C21.4882 27.5574 21.0867 27.2972 20.7487 26.9691C20.4106 26.641 20.1425 26.2514 19.9597 25.8227C19.777 25.3939 19.6831 24.9344 19.6836 24.4704V18.2394C19.6836 17.3025 20.0671 16.4041 20.7497 15.7416C21.4322 15.0792 22.358 14.707 23.3234 14.707H25.143C25.4011 14.707 25.6585 14.7333 25.9108 14.7857ZM25.0085 24.9315C25.0963 24.7927 25.1429 24.633 25.143 24.4701H25.1439C25.1442 24.2777 25.0797 24.0905 24.9603 23.937C24.8409 23.7835 24.6731 23.672 24.4824 23.6195C24.2917 23.567 24.0886 23.5763 23.9039 23.6461C23.7192 23.716 23.563 23.8424 23.4591 24.0062C23.3553 24.1701 23.3094 24.3623 23.3284 24.5538C23.3475 24.7453 23.4304 24.9255 23.5647 25.067C23.6989 25.2085 23.8771 25.3036 24.0722 25.3378C24.2672 25.3721 24.4684 25.3435 24.6453 25.2566C24.7949 25.1828 24.9206 25.0703 25.0085 24.9315Z"
              fill="#E5E7EB"
            />
            <path
              d="M24.2351 23.5829C24.0218 23.5832 23.8153 23.6562 23.6518 23.7892C23.4883 23.9222 23.3781 24.1066 23.3405 24.3104C23.3028 24.5142 23.3401 24.7244 23.4459 24.9042C23.5516 25.084 23.7191 25.2221 23.919 25.2943H24.093C24.2787 25.294 24.4641 25.2802 24.6476 25.2529C24.8302 25.1632 24.9763 25.0163 25.0623 24.8361C25.1483 24.6558 25.1691 24.4527 25.1215 24.2595C25.0739 24.0663 24.9605 23.8944 24.7997 23.7715C24.639 23.6485 24.4402 23.5818 24.2355 23.582L24.2351 23.5829Z"
              fill="#374151"
            />
            <path
              d="M27.7317 11.5326V14.174C27.2547 13.8872 26.7305 13.6822 26.1819 13.5679C26.0928 13.5497 26.0036 13.5336 25.9136 13.5199V11.5326C25.9136 11.3005 25.8665 11.0706 25.7749 10.8562C25.6833 10.6418 25.549 10.4471 25.3798 10.283C25.2106 10.119 25.0098 9.98896 24.7887 9.90033C24.5677 9.8117 24.3308 9.76622 24.0917 9.76649H10.64C10.1574 9.76649 9.69448 9.95256 9.35321 10.2838C9.01193 10.615 8.82021 11.0642 8.82021 11.5326V21.7628C8.82021 21.9948 8.86728 22.2244 8.95873 22.4387C9.05018 22.653 9.18423 22.8477 9.35321 23.0116C9.52219 23.1756 9.7228 23.3057 9.94359 23.3945C10.1644 23.4832 10.401 23.5289 10.64 23.5289H18.4037V24.4674C18.4036 24.745 18.4284 25.022 18.4779 25.2954H18.062V26.2339H19.0291C19.2704 26.2339 19.5018 26.327 19.6725 26.4926C19.8431 26.6582 19.939 26.8828 19.939 27.117C19.939 27.3512 19.8431 27.5758 19.6725 27.7414C19.5018 27.907 19.2704 28 19.0291 28H15.2752C15.0339 28 14.8024 27.907 14.6318 27.7414C14.4612 27.5758 14.3653 27.3512 14.3653 27.117C14.3653 26.8828 14.4612 26.6582 14.6318 26.4926C14.8024 26.327 15.0339 26.2339 15.2752 26.2339H16.2427V25.2954H10.64C9.67464 25.2953 8.74886 24.9231 8.06625 24.2606C7.38365 23.5982 7.00011 22.6997 7 21.7628V11.5326C7.00011 10.5957 7.38365 9.69725 8.06625 9.03479C8.74886 8.37233 9.67464 8.00011 10.64 8H24.0917C25.057 8.00011 25.9828 8.37233 26.6654 9.03479C27.348 9.69725 27.7316 10.5957 27.7317 11.5326Z"
              fill="#374151"
            />
            <path
              d="M24.2345 23.5829C24.0968 23.5826 23.9607 23.6126 23.8367 23.6708C23.7126 23.7289 23.6038 23.8136 23.5184 23.9185C23.433 24.0234 23.3732 24.1457 23.3436 24.2763C23.3139 24.4069 23.3152 24.5422 23.3473 24.6723C23.3795 24.8023 23.4416 24.9235 23.529 25.0268C23.6164 25.1302 23.7268 25.2129 23.8519 25.2688C23.9771 25.3247 24.1136 25.3523 24.2514 25.3496C24.3891 25.3468 24.5244 25.3138 24.647 25.2529C24.8296 25.1632 24.9757 25.0163 25.0617 24.8361C25.1477 24.6558 25.1686 24.4527 25.1209 24.2595C25.0733 24.0663 24.9599 23.8944 24.7992 23.7715C24.6384 23.6485 24.4396 23.5818 24.2349 23.582L24.2345 23.5829Z"
              fill="#374151"
            />
            <path
              d="M27.7314 15.7518C27.235 15.2632 26.6023 14.9261 25.9112 14.7818C25.6588 14.7294 25.4014 14.7031 25.1433 14.7031H23.3236C22.3582 14.7031 21.4323 15.0753 20.7497 15.7378C20.0671 16.4003 19.6836 17.2988 19.6836 18.2357V24.467C19.6831 24.9311 19.777 25.3906 19.9598 25.8194C20.1425 26.2482 20.4106 26.6378 20.7487 26.9659C21.0868 27.294 21.4883 27.5542 21.9301 27.7316C22.3719 27.909 22.8454 28 23.3236 27.9996H25.1438C26.1091 27.9995 27.0349 27.6273 27.7175 26.9648C28.4001 26.3023 28.7836 25.4039 28.7838 24.467V18.2357C28.7851 17.3052 28.4068 16.4121 27.7314 15.7518ZM26.9636 24.467C26.9636 24.699 26.9166 24.9286 26.8251 25.1429C26.7337 25.3572 26.5997 25.5519 26.4307 25.716C26.2617 25.88 26.061 26.01 25.8402 26.0988C25.6194 26.1875 25.3828 26.2332 25.1438 26.2331H23.3236C23.2035 26.2333 23.0838 26.2218 22.9661 26.1987C22.5536 26.1186 22.1825 25.9023 21.9158 25.5866C21.6491 25.2709 21.5034 24.8753 21.5034 24.467V18.2357C21.5035 17.7672 21.6953 17.318 22.0366 16.9867C22.378 16.6555 22.8409 16.4693 23.3236 16.4692H25.1438C25.3828 16.4691 25.6195 16.5147 25.8404 16.6034C26.0613 16.6922 26.2619 16.8223 26.431 16.9864C26.6 17.1504 26.734 17.3452 26.8254 17.5596C26.9168 17.7739 26.9637 18.0037 26.9636 18.2357V24.467Z"
              fill="#374151"
            />
            <path
              d="M25.1451 24.4662C25.145 24.6291 25.0984 24.7888 25.0105 24.9277C24.9227 25.0665 24.797 25.179 24.6473 25.2528C24.4705 25.3397 24.2692 25.3683 24.0742 25.334C23.8791 25.2998 23.7009 25.2047 23.5667 25.0632C23.4324 24.9216 23.3494 24.7414 23.3304 24.5499C23.3113 24.3585 23.3572 24.1662 23.4611 24.0023C23.565 23.8385 23.7211 23.7121 23.9058 23.6422C24.0905 23.5724 24.2937 23.5631 24.4844 23.6156C24.6751 23.6681 24.8429 23.7796 24.9623 23.9331C25.0817 24.0866 25.1462 24.2738 25.146 24.4662H25.1451Z"
              fill="#374151"
            />
            <path
              d="M25.1451 24.4662C25.145 24.6291 25.0984 24.7888 25.0105 24.9277C24.9227 25.0665 24.797 25.179 24.6473 25.2528C24.4705 25.3397 24.2692 25.3683 24.0742 25.334C23.8791 25.2998 23.7009 25.2047 23.5667 25.0632C23.4324 24.9216 23.3494 24.7414 23.3304 24.5499C23.3113 24.3585 23.3572 24.1662 23.4611 24.0023C23.565 23.8385 23.7211 23.7121 23.9058 23.6422C24.0905 23.5724 24.2937 23.5631 24.4844 23.6156C24.6751 23.6681 24.8429 23.7796 24.9623 23.9331C25.0817 24.0866 25.1462 24.2738 25.146 24.4662H25.1451Z"
              fill="#374151"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_13826_156219">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(6 6)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    darkIcon: () => (
      <svg
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_13927_92291)">
          <mask
            id="mask0_13927_92291"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="6"
            y="6"
            width="25"
            height="24"
          >
            <rect x="6.5" y="6" width="24" height="24" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask0_13927_92291)">
            <rect
              x="10.5"
              y="11"
              width="15"
              height="11.5"
              rx="1"
              fill="#374151"
            />
            <path
              d="M28.2305 11.5324V14.1737C27.7536 13.8869 27.2294 13.6818 26.6808 13.5676C26.5917 13.5494 26.5025 13.5333 26.4125 13.5196V11.5324C26.4125 11.3003 26.3654 11.0705 26.2738 10.8561C26.1822 10.6417 26.048 10.4469 25.8788 10.2829C25.7096 10.1189 25.5087 9.98885 25.2877 9.90022C25.0667 9.81159 24.8298 9.76612 24.5907 9.76639H11.1398C10.6572 9.76639 10.1943 9.95245 9.85307 10.2836C9.51182 10.6148 9.3201 11.064 9.3201 11.5324V21.762C9.3201 21.994 9.36717 22.2236 9.45862 22.4379C9.55006 22.6521 9.6841 22.8468 9.85307 23.0108C10.022 23.1748 10.2226 23.3048 10.4434 23.3936C10.6642 23.4823 10.9008 23.528 11.1398 23.528H18.903V24.4665C18.9029 24.744 18.9278 25.021 18.9773 25.2944H18.5614V26.2329H19.5284C19.7697 26.2329 20.0011 26.3259 20.1717 26.4915C20.3423 26.6571 20.4382 26.8817 20.4382 27.1159C20.4382 27.35 20.3423 27.5746 20.1717 27.7402C20.0011 27.9058 19.7697 27.9988 19.5284 27.9988H15.7747C15.5334 27.9988 15.302 27.9058 15.1313 27.7402C14.9607 27.5746 14.8649 27.35 14.8649 27.1159C14.8649 26.8817 14.9607 26.6571 15.1313 26.4915C15.302 26.3259 15.5334 26.2329 15.7747 26.2329H16.7421V25.2944H11.1398C10.1745 25.2943 9.24876 24.9221 8.56619 24.2597C7.88362 23.5973 7.50011 22.6989 7.5 21.762V11.5324C7.50011 10.5956 7.88362 9.69715 8.56619 9.03473C9.24876 8.3723 10.1745 8.00011 11.1398 8H24.5907C25.556 8.00011 26.4817 8.3723 27.1643 9.03473C27.8468 9.69715 28.2304 10.5956 28.2305 11.5324Z"
              fill="#374151"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.4108 14.7857C27.1019 14.93 27.7345 15.2671 28.2309 15.7556C28.9063 16.416 29.2846 17.309 29.2832 18.2394V24.4704C29.2831 25.4072 28.8996 26.3056 28.217 26.968C27.5345 27.6304 26.6088 28.0026 25.6435 28.0027H23.8234C23.3453 28.0032 22.8718 27.9121 22.43 27.7347C21.9882 27.5574 21.5867 27.2972 21.2487 26.9691C20.9106 26.641 20.6425 26.2514 20.4597 25.8227C20.277 25.3939 20.1831 24.9344 20.1836 24.4704V18.2394C20.1836 17.3025 20.5671 16.4041 21.2497 15.7416C21.9322 15.0792 22.858 14.707 23.8234 14.707H25.643C25.9011 14.707 26.1585 14.7333 26.4108 14.7857ZM25.5085 24.9315C25.5963 24.7927 25.6429 24.633 25.643 24.4701H25.6439C25.6442 24.2777 25.5797 24.0905 25.4603 23.937C25.3409 23.7835 25.1731 23.672 24.9824 23.6195C24.7917 23.567 24.5886 23.5763 24.4039 23.6461C24.2192 23.716 24.063 23.8424 23.9591 24.0062C23.8553 24.1701 23.8094 24.3623 23.8284 24.5538C23.8475 24.7453 23.9304 24.9255 24.0647 25.067C24.1989 25.2085 24.3771 25.3036 24.5722 25.3378C24.7672 25.3721 24.9684 25.3435 25.1453 25.2566C25.2949 25.1828 25.4206 25.0703 25.5085 24.9315Z"
              fill="#374151"
            />
            <path
              d="M24.7351 23.5829C24.5218 23.5832 24.3153 23.6562 24.1518 23.7892C23.9883 23.9222 23.8781 24.1066 23.8405 24.3104C23.8028 24.5142 23.8401 24.7244 23.9459 24.9042C24.0516 25.084 24.2191 25.2221 24.419 25.2943H24.593C24.7787 25.294 24.9641 25.2802 25.1476 25.2529C25.3302 25.1632 25.4763 25.0163 25.5623 24.8361C25.6483 24.6558 25.6691 24.4527 25.6215 24.2595C25.5739 24.0663 25.4605 23.8944 25.2997 23.7715C25.139 23.6485 24.9402 23.5818 24.7355 23.582L24.7351 23.5829Z"
              fill="#E5E7EB"
            />
            <path
              d="M28.2317 11.5326V14.174C27.7547 13.8872 27.2305 13.6822 26.6819 13.5679C26.5928 13.5497 26.5036 13.5336 26.4136 13.5199V11.5326C26.4136 11.3005 26.3665 11.0706 26.2749 10.8562C26.1833 10.6418 26.049 10.4471 25.8798 10.283C25.7106 10.119 25.5098 9.98896 25.2887 9.90033C25.0677 9.8117 24.8308 9.76622 24.5917 9.76649H11.14C10.6574 9.76649 10.1945 9.95256 9.85321 10.2838C9.51193 10.615 9.32021 11.0642 9.32021 11.5326V21.7628C9.32021 21.9948 9.36728 22.2244 9.45873 22.4387C9.55018 22.653 9.68423 22.8477 9.85321 23.0116C10.0222 23.1756 10.2228 23.3057 10.4436 23.3945C10.6644 23.4832 10.901 23.5289 11.14 23.5289H18.9037V24.4674C18.9036 24.745 18.9284 25.022 18.9779 25.2954H18.562V26.2339H19.5291C19.7704 26.2339 20.0018 26.327 20.1725 26.4926C20.3431 26.6582 20.439 26.8828 20.439 27.117C20.439 27.3512 20.3431 27.5758 20.1725 27.7414C20.0018 27.907 19.7704 28 19.5291 28H15.7752C15.5339 28 15.3024 27.907 15.1318 27.7414C14.9612 27.5758 14.8653 27.3512 14.8653 27.117C14.8653 26.8828 14.9612 26.6582 15.1318 26.4926C15.3024 26.327 15.5339 26.2339 15.7752 26.2339H16.7427V25.2954H11.14C10.1746 25.2953 9.24886 24.9231 8.56625 24.2606C7.88365 23.5982 7.50011 22.6997 7.5 21.7628V11.5326C7.50011 10.5957 7.88365 9.69725 8.56625 9.03479C9.24886 8.37233 10.1746 8.00011 11.14 8H24.5917C25.557 8.00011 26.4828 8.37233 27.1654 9.03479C27.848 9.69725 28.2316 10.5957 28.2317 11.5326Z"
              fill="#E5E7EB"
            />
            <path
              d="M24.7345 23.5829C24.5968 23.5826 24.4607 23.6126 24.3367 23.6708C24.2126 23.7289 24.1038 23.8136 24.0184 23.9185C23.933 24.0234 23.8732 24.1457 23.8436 24.2763C23.8139 24.4069 23.8152 24.5422 23.8473 24.6723C23.8795 24.8023 23.9416 24.9235 24.029 25.0268C24.1164 25.1302 24.2268 25.2129 24.3519 25.2688C24.4771 25.3247 24.6136 25.3523 24.7514 25.3496C24.8891 25.3468 25.0244 25.3138 25.147 25.2529C25.3296 25.1632 25.4757 25.0163 25.5617 24.8361C25.6477 24.6558 25.6686 24.4527 25.6209 24.2595C25.5733 24.0663 25.4599 23.8944 25.2992 23.7715C25.1384 23.6485 24.9396 23.5818 24.7349 23.582L24.7345 23.5829Z"
              fill="#E5E7EB"
            />
            <path
              d="M28.2314 15.7518C27.735 15.2632 27.1023 14.9261 26.4112 14.7818C26.1588 14.7294 25.9014 14.7031 25.6433 14.7031H23.8236C22.8582 14.7031 21.9323 15.0753 21.2497 15.7378C20.5671 16.4003 20.1836 17.2988 20.1836 18.2357V24.467C20.1831 24.9311 20.277 25.3906 20.4598 25.8194C20.6425 26.2482 20.9106 26.6378 21.2487 26.9659C21.5868 27.294 21.9883 27.5542 22.4301 27.7316C22.8719 27.909 23.3454 28 23.8236 27.9996H25.6438C26.6091 27.9995 27.5349 27.6273 28.2175 26.9648C28.9001 26.3023 29.2836 25.4039 29.2838 24.467V18.2357C29.2851 17.3052 28.9068 16.4121 28.2314 15.7518ZM27.4636 24.467C27.4636 24.699 27.4166 24.9286 27.3251 25.1429C27.2337 25.3572 27.0997 25.5519 26.9307 25.716C26.7617 25.88 26.561 26.01 26.3402 26.0988C26.1194 26.1875 25.8828 26.2332 25.6438 26.2331H23.8236C23.7035 26.2333 23.5838 26.2218 23.4661 26.1987C23.0536 26.1186 22.6825 25.9023 22.4158 25.5866C22.1491 25.2709 22.0034 24.8753 22.0034 24.467V18.2357C22.0035 17.7672 22.1953 17.318 22.5366 16.9867C22.878 16.6555 23.3409 16.4693 23.8236 16.4692H25.6438C25.8828 16.4691 26.1195 16.5147 26.3404 16.6034C26.5613 16.6922 26.7619 16.8223 26.931 16.9864C27.1 17.1504 27.234 17.3452 27.3254 17.5596C27.4168 17.7739 27.4637 18.0037 27.4636 18.2357V24.467Z"
              fill="#E5E7EB"
            />
            <path
              d="M25.6451 24.4662C25.645 24.6291 25.5984 24.7888 25.5105 24.9277C25.4227 25.0665 25.297 25.179 25.1473 25.2528C24.9705 25.3397 24.7692 25.3683 24.5742 25.334C24.3791 25.2998 24.2009 25.2047 24.0667 25.0632C23.9324 24.9216 23.8494 24.7414 23.8304 24.5499C23.8113 24.3585 23.8572 24.1662 23.9611 24.0023C24.065 23.8385 24.2211 23.7121 24.4058 23.6422C24.5905 23.5724 24.7937 23.5631 24.9844 23.6156C25.1751 23.6681 25.3429 23.7796 25.4623 23.9331C25.5817 24.0866 25.6462 24.2738 25.646 24.4662H25.6451Z"
              fill="#E5E7EB"
            />
            <path
              d="M25.6451 24.4662C25.645 24.6291 25.5984 24.7888 25.5105 24.9277C25.4227 25.0665 25.297 25.179 25.1473 25.2528C24.9705 25.3397 24.7692 25.3683 24.5742 25.334C24.3791 25.2998 24.2009 25.2047 24.0667 25.0632C23.9324 24.9216 23.8494 24.7414 23.8304 24.5499C23.8113 24.3585 23.8572 24.1662 23.9611 24.0023C24.065 23.8385 24.2211 23.7121 24.4058 23.6422C24.5905 23.5724 24.7937 23.5631 24.9844 23.6156C25.1751 23.6681 25.3429 23.7796 25.4623 23.9331C25.5817 24.0866 25.6462 24.2738 25.646 24.4662H25.6451Z"
              fill="#E5E7EB"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_13927_92291">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(6.5 6)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    default: true,
  },
  {
    text: 'iOS',
    icon: () => (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0332 27.4909C14.3905 27.4556 13.7858 27.1723 13.3437 26.6995C12.8148 26.1852 12.3429 25.6144 11.9363 24.9969C11.301 24.0775 10.7989 23.071 10.4451 22.0081C10.0443 20.8647 9.83325 19.6621 9.82057 18.4491C9.78977 17.2588 10.0817 16.0826 10.6648 15.0481C11.0916 14.3022 11.7004 13.6794 12.433 13.2391C13.1575 12.8006 13.9843 12.5646 14.8288 12.5552C15.4653 12.5932 16.0909 12.7403 16.6787 12.9901C17.1474 13.1985 17.6407 13.3451 18.1464 13.4261C18.7045 13.3079 19.2498 13.1349 19.7746 12.9095C20.3957 12.6644 21.0533 12.5271 21.7196 12.5035C21.8085 12.5035 21.8964 12.5035 21.9813 12.5138C23.4424 12.5567 24.8013 13.2816 25.6607 14.4767C24.2942 15.2153 23.4551 16.6683 23.4909 18.2342C23.4773 19.4218 23.9673 20.5585 24.8369 21.3573C25.2283 21.7342 25.684 22.0363 26.182 22.2488C26.0798 22.5588 25.9622 22.8584 25.8376 23.16C25.5541 23.8267 25.2045 24.4626 24.794 25.0579C24.4065 25.6542 23.9555 26.2057 23.449 26.7026C22.9866 27.1663 22.3703 27.4399 21.7196 27.4702C21.168 27.4455 20.6262 27.3147 20.1231 27.0848C19.5806 26.8459 18.9976 26.7151 18.406 26.6995C17.7978 26.7116 17.1975 26.8417 16.6378 27.0828C16.1544 27.3032 15.638 27.4405 15.1098 27.4888L15.0332 27.4909ZM18.2527 12.5035C18.1761 12.5035 18.0994 12.5035 18.0227 12.4942C18.0066 12.3712 17.9981 12.2474 17.9972 12.1233C18.0306 11.0914 18.4296 10.1059 19.1215 9.34631C19.5082 8.9099 19.9769 8.55556 20.5003 8.3039C20.9887 8.04975 21.5225 7.89695 22.0702 7.85449C22.0855 7.98983 22.0855 8.12207 22.0855 8.24811C22.071 9.26381 21.6935 10.2399 21.0225 10.9962C20.3649 11.8875 19.3509 12.4393 18.2527 12.5035Z"
          fill="#2e3139"
        />
      </svg>
    ),
    darkIcon: () => (
      <svg
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5332 27.4909C14.8905 27.4556 14.2858 27.1723 13.8437 26.6995C13.3148 26.1852 12.8429 25.6144 12.4363 24.9969C11.801 24.0775 11.2989 23.071 10.9451 22.0081C10.5443 20.8647 10.3333 19.6621 10.3206 18.4491C10.2898 17.2588 10.5817 16.0826 11.1648 15.0481C11.5916 14.3022 12.2004 13.6794 12.933 13.2391C13.6575 12.8006 14.4843 12.5646 15.3288 12.5552C15.9653 12.5932 16.5909 12.7403 17.1787 12.9901C17.6474 13.1985 18.1407 13.3451 18.6464 13.4261C19.2045 13.3079 19.7498 13.1349 20.2746 12.9095C20.8957 12.6644 21.5533 12.5271 22.2196 12.5035C22.3085 12.5035 22.3964 12.5035 22.4813 12.5138C23.9424 12.5567 25.3013 13.2816 26.1607 14.4767C24.7942 15.2153 23.9551 16.6683 23.9909 18.2342C23.9773 19.4218 24.4673 20.5585 25.3369 21.3573C25.7283 21.7342 26.184 22.0363 26.682 22.2488C26.5798 22.5588 26.4622 22.8584 26.3376 23.16C26.0541 23.8267 25.7045 24.4626 25.294 25.0579C24.9065 25.6542 24.4555 26.2057 23.949 26.7026C23.4866 27.1663 22.8703 27.4399 22.2196 27.4702C21.668 27.4455 21.1262 27.3147 20.6231 27.0848C20.0806 26.8459 19.4976 26.7151 18.906 26.6995C18.2978 26.7116 17.6975 26.8417 17.1378 27.0828C16.6544 27.3032 16.138 27.4405 15.6098 27.4888L15.5332 27.4909ZM18.7527 12.5035C18.6761 12.5035 18.5994 12.5035 18.5227 12.4942C18.5066 12.3712 18.4981 12.2474 18.4972 12.1233C18.5306 11.0914 18.9296 10.1059 19.6215 9.34631C20.0082 8.9099 20.4769 8.55556 21.0003 8.3039C21.4887 8.04975 22.0225 7.89695 22.5702 7.85449C22.5855 7.98983 22.5855 8.12207 22.5855 8.24811C22.571 9.26381 22.1935 10.2399 21.5225 10.9962C20.8649 11.8875 19.8509 12.4393 18.7527 12.5035Z"
          fill="#F9FAFB"
        />
      </svg>
    ),
    default: false,
  },
  {
    text: 'Android',
    icon: () => (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.4704 13.3086V23.8015C24.4704 25.0189 24.1919 25.2993 22.9817 25.3027H22.3251C22.3251 26.532 22.3352 27.6724 22.3184 28.8111C22.3133 29.1292 22.2745 29.4643 22.1614 29.7567C21.8947 30.4508 21.1351 30.8646 20.4549 30.7432C19.7055 30.6098 19.1198 29.967 19.1012 29.1839C19.0742 28.0947 19.0894 27.0039 19.0877 25.9131V25.3352H16.912V27.2792C16.912 27.8588 16.9205 28.4384 16.9103 29.0163C16.8918 30.0148 16.2048 30.7586 15.3085 30.762C14.4173 30.7654 13.7 30.0217 13.6797 29.03C13.6595 28.0246 13.6746 27.0193 13.6746 26.0139V25.2839C13.2409 25.2839 12.8577 25.3112 12.4796 25.277C11.9142 25.224 11.5462 24.8735 11.5445 24.3264C11.531 20.6658 11.5361 17.0051 11.5361 13.3086H24.4704Z"
          fill="#4EBD80"
        />
        <path
          d="M11.5254 12.1429H24.4766C24.399 9.9424 23.5196 8.2292 21.7608 6.89044C22.203 6.463 22.6149 6.06804 23.025 5.66966C23.3035 5.39952 23.5061 5.09518 23.1584 4.75322C22.8107 4.40956 22.5203 4.64551 22.2537 4.91736C21.8992 5.28154 21.5312 5.63034 21.1886 6.00478C21.0029 6.20482 20.8476 6.25099 20.5776 6.13985C18.866 5.43884 17.1393 5.43713 15.4261 6.13814C15.1611 6.24586 15.0041 6.21337 14.8151 6.00991C14.4235 5.58759 14.0252 5.17041 13.5931 4.79255C13.4563 4.67115 13.1913 4.61644 13.0124 4.65406C12.8757 4.68483 12.6917 4.91223 12.6917 5.05243C12.6917 5.24393 12.8065 5.47304 12.9415 5.6235C13.3416 6.06804 13.7737 6.48352 14.2041 6.91951C12.4554 8.25827 11.5929 9.96975 11.5254 12.1429ZM20.7008 8.64297C21.1481 8.64468 21.5093 9.00544 21.5093 9.44827C21.5076 9.88426 21.1346 10.2587 20.6991 10.2587C20.2636 10.2587 19.9058 9.90136 19.9058 9.44656C19.9058 8.99176 20.2484 8.64297 20.7008 8.64297ZM15.2995 8.64297C15.7519 8.64297 16.0945 8.99005 16.0928 9.44827C16.0928 9.90307 15.7418 10.2604 15.2978 10.2587C14.8624 10.257 14.4893 9.88084 14.4893 9.44656C14.4893 9.01228 14.8539 8.64468 15.2995 8.64297Z"
          fill="#4EBD80"
        />
        <path
          d="M10.4377 18.7221C10.4377 19.948 10.4427 21.1722 10.436 22.3981C10.4309 23.4325 9.75239 24.1934 8.84262 24.2054C7.9244 24.2173 7.20705 23.4308 7.20367 22.381C7.19861 19.9463 7.19692 17.5133 7.20367 15.0786C7.20705 14.0459 7.89571 13.2884 8.80886 13.2765C9.74227 13.2662 10.4309 14.0305 10.436 15.0957C10.4427 16.3045 10.4377 17.5133 10.4377 18.7221Z"
          fill="#4EBD80"
        />
        <path
          d="M28.7944 22.4032C28.791 23.4239 28.0889 24.1968 27.1842 24.2053C26.2592 24.2156 25.5672 23.441 25.5621 22.3758C25.5554 21.167 25.5604 19.9582 25.5604 18.7494C25.5604 17.5406 25.5554 16.2993 25.5621 15.0734C25.5672 14.039 26.2474 13.2833 27.1605 13.2764C28.0754 13.2696 28.7894 14.0287 28.7927 15.0495C28.8012 17.5013 28.8012 19.9514 28.7944 22.4032Z"
          fill="#4EBD80"
        />
      </svg>
    ),
    default: false,
  },
  {
    text: 'Mac',
    icon: () => (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00195 23.6311V12.3693C8.00195 10.0347 10.0444 8.00049 12.3708 8.00049H18.1959C18.1959 8.00049 15.6717 14.3338 15.6717 19.1652C15.6717 19.4575 15.9555 19.7477 16.2542 19.7477H18.6813C18.6813 25.3565 19.445 26.8645 19.7492 27.9999L12.3708 28C10.0506 28 8.00195 25.9686 8.00195 23.6311Z"
          fill="url(#paint0_linear_13826_156247)"
          stroke="#474747"
          strokeWidth="0.500001"
        />
        <path
          d="M13.2441 14.2134V15.4755"
          stroke="#474747"
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <path
          d="M16.2544 19.7477C15.958 19.7477 15.6719 19.4576 15.6719 19.1652C15.6719 14.2995 18.1961 8.00049 18.1961 8.00049H23.6328C25.9704 8.00049 28.0016 10.0387 28.0016 12.3693V23.6311C28.0016 25.9545 25.9707 27.9999 23.6328 27.9999H19.7494C19.448 26.8751 18.6815 25.564 18.6815 19.7477H16.2544Z"
          fill="url(#paint1_linear_13826_156247)"
          stroke="#474747"
          strokeWidth="0.500001"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0289 13.7671C22.2498 13.7671 22.4289 13.9462 22.4289 14.1671V15.4292C22.4289 15.6501 22.2498 15.8292 22.0289 15.8292C21.808 15.8292 21.6289 15.6501 21.6289 15.4292V14.1671C21.6289 13.9462 21.808 13.7671 22.0289 13.7671Z"
          fill="#474747"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6825 21.1518C11.8738 21.0413 12.1184 21.1069 12.2289 21.2982C12.6057 21.9509 13.3417 22.511 14.3139 22.9094C15.28 23.3054 16.4414 23.5253 17.6105 23.5253C18.7778 23.5253 19.9375 23.3081 20.9032 22.9136C21.8746 22.5167 22.6119 21.9568 22.9921 21.2982C23.1025 21.1069 23.3472 21.0413 23.5385 21.1518C23.7298 21.2623 23.7953 21.5069 23.6849 21.6982C23.1843 22.5652 22.2713 23.2188 21.2057 23.6541C20.1344 24.0918 18.8705 24.3253 17.6105 24.3253C16.3475 24.3253 15.0819 24.0887 14.0106 23.6496C12.9453 23.213 12.0334 22.5595 11.5361 21.6982C11.4256 21.5069 11.4912 21.2623 11.6825 21.1518Z"
          fill="#474747"
        />
        <defs>
          <linearGradient
            id="paint0_linear_13826_156247"
            x1="12.3714"
            y1="27.9991"
            x2="12.3714"
            y2="8.00865"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1D6AF2" />
            <stop offset="1" stopColor="#1AD1FD" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_13826_156247"
            x1="23.6328"
            y1="27.9999"
            x2="23.6328"
            y2="7.99857"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D1DBE6" />
            <stop offset="1" stopColor="#F1F1F3" stopOpacity="0.983122" />
          </linearGradient>
        </defs>
      </svg>
    ),
    default: false,
  },
  {
    text: 'Windows',
    icon: () => (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 12.8251V17.6562H17.6562V8H8V12.8251ZM18.3437 12.8251V17.6562H27.9999V8H18.3437V12.8251ZM8 23.1748V27.9999H17.6562V18.3437H8V23.1748ZM18.3437 23.1748V27.9999H27.9999V18.3437H18.3437V23.1748Z"
          fill="#0078D4"
        />
      </svg>
    ),
    default: false,
  },
];

export const isValueTrue = (value: any) => {
  if (value === 'coming_soon') return false;
  if (value === 'contact_sales') return false;
  return (
    value === 'true' ||
    value === true ||
    value?.toLowerCase?.() === 'true' ||
    !!value?.includes?.('user')
  );
};

export const renderLanguageName = (code: string, locales?: ILocalesModel[]) => {
  if (!locales?.length) {
    return 'English';
  }
  return (
    locales.filter((item) => item.language_code == code)?.[0]?.display_name ||
    'English'
  );
};

export const SIMPLE_RICH_TEXT = 'text-section.simple-text-section';
export const RICH_TEXT_WITH_TITLE =
  'text-section-with-title.text-section-with-title';
export const RICH_TEXT_FEATURED_SECTION = 'featured-section.featured-section';
export const RICH_TEXT_IMAGE = 'image.image';
export const RICH_TEXT_VIDEO = 'video.video';

export const getMediaExtension = (media?: IMedia) => {
  if (media?.data?.attributes?.mime) {
    return media?.data?.attributes?.mime || '';
  }
  return '';
};

export const getMediaType = (media?: IMedia) => {
  if (media?.data?.attributes?.mime) {
    return (_.get(media, 'data.attributes.mime')! as string)?.includes('video')
      ? 'video'
      : 'image';
  }
  return 'image';
};

export const getImageUrl = (image?: IMedia, highQuality = false) => {
  if (typeof image === 'string') {
    if (highQuality) {
      return `${image} 2x`;
    }
    return image;
  }
  let url =
    _.get(image, 'data.attributes.url') ||
    _.get(image, 'attributes.url') ||
    '/logo-light.svg';
  if (url === '/logo-light.svg') {
    return '';
  }
  if (url.startsWith('data:image') || url.startsWith('data:video')) {
    return url;
  }
  if (/^[a-zA-Z]/.test(url) && !/^https?:\/\//.test(url)) {
    url = `https://${url}`;
  } else if (url.startsWith('/')) {
    url = MEDIA_URL + url;
  }
  const isVideo = getMediaType(image) === 'video';
  if (
    image &&
    isVideo &&
    !url.includes('https://') &&
    !url.includes('http://')
  ) {
    url = `https://${url}`;
  }
  if (isVideo) {
    return url;
  }
  if (highQuality) {
    return `${url} 2x`;
  }
  return url;
};

export const getImageAlt = (image?: IMedia) => {
  if (image?.data?.attributes?.alternativeText) {
    return image?.data?.attributes?.alternativeText;
  }
  return '';
};

export const getImagePlaceholder = (image?: IMedia) => {
  if (image?.data?.attributes?.placeholder) {
    return image?.data?.attributes?.placeholder;
  }
  return '';
};

export const INT_MAX = 2147483647;

export const isUnlimited = (value: number | string) =>
  Number.isNaN(parseInt(value as string, 10)) ||
  (value as number) >= INT_MAX ||
  parseInt(value as string, 10) >= INT_MAX;

export const getUnlimitedValue = (value: number | string) =>
  isUnlimited(value) ? INT_MAX : parseInt(value as string, 10);

export const checkIsStringUnlimited = (value: string) => {
  const valueToCheck = value?.toLowerCase?.() || '';
  return valueToCheck === 'unlimited' || valueToCheck.includes('unlimited');
};

export const checkIsStringANumber = (value: string) =>
  !Number.isNaN(parseInt(value, 10));

export const checkIsStringComingSoon = (value: string) => {
  const valueToCheck = value?.toLowerCase?.() || '';
  return (
    valueToCheck === 'coming soon' ||
    valueToCheck.includes('coming soon') ||
    valueToCheck.includes('coming_soon') ||
    valueToCheck.includes('soon') ||
    valueToCheck.includes('coming')
  );
};

export const checkIsStringContactSales = (value: string) => {
  const valueToCheck = value?.toLowerCase?.() || '';
  return (
    valueToCheck === 'contact sales' ||
    valueToCheck.includes('contact sales') ||
    valueToCheck.includes('contact_sales') ||
    valueToCheck.includes('sales') ||
    valueToCheck.includes('contact')
  );
};

export const getImageDimensions = (image?: IMedia) => {
  if (image?.data?.attributes?.width) {
    const width = _.get(image, 'data.attributes.width') || 0;
    const height = _.get(image, 'data.attributes.height') || 0;
    return {
      width,
      height,
    };
  }
  return {
    width: 0,
    height: 0,
  };
};

export const getQueryString = (
  config: string[],
  locale = 'en',
  sort?: string[],
  pagination?: IPagination,
  filters?: string[],
) =>
  qs.stringify(
    {
      populate: config,
      locale,
      ...(sort ? { sort } : {}),
      ...(pagination ? { pagination } : {}),
      ...(filters ? { filters } : {}),
    },
    {
      encodeValuesOnly: true,
    },
  );

export const getBlogFilterQueryString = (
  currentLocale = 'en',
  searchField: string,
) =>
  qs.stringify(
    {
      populate: ['user_image,display_image,primary_topic,topics'],
      locale: currentLocale,
      sort: ['sort_id:asc'],
      pagination: { page: 1, pageSize: 50 },
      filters: {
        $or: [
          {
            short_description: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            slug: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            title: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            user_description: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            user_name: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            primary_topic: {
              topic_name: {
                $containsi: searchField.toLowerCase(),
              },
            },
          },
          {
            primary_topic: {
              slug: {
                $containsi: searchField.toLowerCase(),
              },
            },
          },
          {
            ...(searchField.toLowerCase().includes('android')
              ? {
                  platform_android: true,
                }
              : {}),
          },
          {
            ...(searchField.toLowerCase().includes('ios')
              ? {
                  platform_ios: true,
                }
              : {}),
          },
          {
            ...(searchField.toLowerCase().includes('mac')
              ? {
                  platform_mac: true,
                }
              : {}),
          },
          {
            ...(searchField.toLowerCase().includes('windows')
              ? {
                  platform_windows: true,
                }
              : {}),
          },
        ],
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

export const SOCIALS = {
  twitter: 'http://twitter.com/intent/tweet/?url=',
  facebook: 'http://www.facebook.com/sharer.php?u=',
};

export function Container({ children, id }: { children: any; id: string }) {
  return (
    <p
      id={id}
      className="font-inter-hero text-sm leading-5 font-semibold text-primary-600 dark:text-primary-500 xl:text-base xl:leading-6 xl:font-semibold"
    >
      {children}
    </p>
  );
}

export function PlanFeature({
  plan,
  index,
  commonData = {},
  setCustomisePlan,
  onPlanChange,
  planIndex,
  detailIndex,
  detailInnerIndex,
}: {
  plan: IPlanFeatureDetail;
  index: 1 | 2 | 3 | 4;
  commonData?: any;
  setCustomisePlan: React.Dispatch<React.SetStateAction<boolean>>;
  onPlanChange: any;
  planIndex: number;
  detailIndex: number;
  detailInnerIndex?: number;
}) {
  const id = `plan_${plan.detail_id}-${index}`;
  const value = plan[`plan_${index}_value`];
  if (checkIsStringUnlimited(value)) {
    return <Container id={id}>{commonData.unlimited}</Container>;
  }
  if (plan.type === 'boolean') {
    if (checkIsStringANumber(value)) {
      return <Container id={id}>{parseInt(value, 10)}</Container>;
    }
    if (isValueTrue(value)) {
      return (
        <Container id={id}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7071 6.29289C20.0976 6.68342 20.0976 7.31658 19.7071 7.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L9 15.5858L18.2929 6.29289C18.6834 5.90237 19.3166 5.90237 19.7071 6.29289Z"
              fill="currentColor"
            />
          </svg>
        </Container>
      );
    }
    if (value === 'false') {
      return (
        <Container id={id}>
          <button
            id={`button_${id}`}
            className="text-primary-600 dark:text-primary-500"
            onClick={() => {
              setCustomisePlan(true);
              onPlanChange(
                index - 1,
                planIndex,
                detailIndex,
                true,
                detailInnerIndex,
              );
            }}
          >
            {commonData.add}
          </button>
        </Container>
      );
    }
    return (
      <Container id={id}>
        <span className="text-gray-600 dark:text-gray-400">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.63796 6.36327C7.28649 6.0118 6.71664 6.0118 6.36517 6.36327C6.01369 6.71475 6.01369 7.28459 6.36517 7.63607L10.3252 11.5961L6.36532 15.556C6.01385 15.9075 6.01385 16.4773 6.36532 16.8288C6.71679 17.1803 7.28664 17.1803 7.63811 16.8288L11.598 12.8689L15.5576 16.8285C15.909 17.1799 16.4789 17.1799 16.8303 16.8285C17.1818 16.477 17.1818 15.9071 16.8303 15.5557L12.8708 11.5961L16.8305 7.6364C17.182 7.28493 17.182 6.71508 16.8305 6.36361C16.479 6.01213 15.9092 6.01213 15.5577 6.36361L11.598 10.3233L7.63796 6.36327Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </Container>
    );
  }
  return <Container id={id}>{value}</Container>;
}

function MobileContainer({ children, id }: { children: any; id: string }) {
  return (
    <p
      id={id}
      className="font-inter-hero text-sm leading-5 text-primary-600 dark:text-primary-500"
    >
      {children}
    </p>
  );
}
export function PlanFeatureMobile({
  plan,
  index,
  commonData = {},
  setCustomisePlan,
  onPlanChange,
  planIndex,
  detailIndex,
  detailInnerIndex,
}: {
  plan: IPlanFeatureDetail;
  index: 1 | 2 | 3 | 4;
  commonData?: any;
  setCustomisePlan: React.Dispatch<React.SetStateAction<boolean>>;
  onPlanChange: any;
  planIndex: number;
  detailIndex: number;
  detailInnerIndex?: number;
}) {
  const id = `mobile_plan_${plan.detail_id}-${index}`;
  const value = plan[`plan_${index}_value`];
  if (checkIsStringUnlimited(value)) {
    return <MobileContainer id={id}>{commonData.unlimited}</MobileContainer>;
  }
  if (plan.type === 'boolean') {
    if (checkIsStringANumber(value)) {
      return <MobileContainer id={id}>{parseInt(value, 10)}</MobileContainer>;
    }
    if (isValueTrue(value)) {
      return (
        <MobileContainer id={id}>
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L5 9.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893Z"
              fill="currentColor"
            />
          </svg>
        </MobileContainer>
      );
    }
    if (value === 'false') {
      return (
        <MobileContainer id={id}>
          <button
            id={`button_${id}`}
            className="text-primary-600 dark:text-primary-500"
            onClick={() => {
              setCustomisePlan(true);
              onPlanChange(
                index - 1,
                planIndex,
                detailIndex,
                true,
                detailInnerIndex,
              );
            }}
          >
            {commonData.add}
          </button>
        </MobileContainer>
      );
    }
    return (
      <MobileContainer id={id}>
        <span className="text-gray-600 dark:text-gray-400">x</span>
      </MobileContainer>
    );
  }
  return <MobileContainer id={id}>{value}</MobileContainer>;
}

export const getCustomiseAblePlanComponent = (
  plan: IPlanFeatureDetail,
  index: 1 | 2 | 3 | 4,
  onPlanChange: () => void,
  planIndex: number,
  detailIndex: number,
  commonData = {},
  setCustomisePlan: React.Dispatch<React.SetStateAction<boolean>>,
  detailIndexI?: number,
  data?: CompareData,
  smsPlans?: ISMSPlan[],
  licensePrices?: PDFLicense[],
) => {
  const id = `${plan.detail_id}-${index}`;
  const is_customisable = plan[`is_editable_for_plan_${index}`];
  if (!is_customisable) {
    return (
      <div id={id}>
        <PlanFeature
          plan={plan}
          index={index}
          commonData={commonData}
          onPlanChange={onPlanChange}
          setCustomisePlan={setCustomisePlan}
          planIndex={planIndex}
          detailIndex={detailIndex}
          detailInnerIndex={detailIndexI}
        />
      </div>
    );
  }
  const defaultValue = plan[`plan_${index}_value`];
  if (plan.type == 'boolean') {
    const isAdjustablePricing = plan.adjustable_pricing;
    if (isAdjustablePricing) {
      const isChecked = isValueTrue(plan[`plan_${index}_value`]);
      if (isChecked) {
        return (
          <AdjustablePriceComponent
            defaultValue={defaultValue}
            onPlanChange={onPlanChange}
            plan={index - 1}
            planIndex={planIndex}
            detailIndex={detailIndex}
            id={id}
            detailInnerIndex={detailIndexI}
            data={data}
            smsPlans={smsPlans}
            licensePrices={licensePrices}
          />
        );
      }
    }
    return (
      <Toggle
        defaultValue={defaultValue}
        onPlanChange={onPlanChange}
        plan={index - 1}
        planIndex={planIndex}
        detailIndex={detailIndex}
        id={id}
        detailInnerIndex={detailIndexI}
      />
    );
  }
  if (plan.type == 'integer') {
    return (
      <NumberInput
        defaultValue={defaultValue}
        onPlanChange={onPlanChange}
        plan={index - 1}
        planIndex={planIndex}
        detailIndex={detailIndex}
        id={id}
        detailInnerIndex={detailIndexI}
      />
    );
  }
  return '';
};

export const prependPreviewLink = (
  link = '/',
  _isPreview?: any,
  locale = 'en',
) => {
  if (link?.includes('mailto:')) {
    return link;
  }
  return `/${locale}${link}`;
};

export const STRIPE_CURRENCIES = [
  'USD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ARS',
  'AUD',
  'AZN',
  'BAM',
  'BDT',
  'BGN',
  'BIF',
  'BND',
  'BOB',
  'BRL',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'EUR',
  'GBP',
  'GEL',
  'GNF',
  'GTQ',
  'HKD',
  'HNL',
  'HRK',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JMD',
  'JPY',
  'KES',
  'KMF',
  'KZT',
  'LBP',
  'LKR',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MOP',
  'MUR',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SEK',
  'SGD',
  'SOS',
  'THB',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VND',
  'XAF',
  'XOF',
  'YER',
  'ZAR',
];

export const findPosition = (obj: {
  offsetTop: number;
  offsetParent?: { offsetTop: number };
}) => {
  let currenttop = 0;
  if (obj.offsetParent) {
    do {
      currenttop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return currenttop;
  }
};

export const getFeatureById = (features: IPlanFeature[], id: string) => {
  try {
    const flatten = (arr: any[]): any =>
      arr.reduce(
        (acc, val) =>
          Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val),
        [],
      );
    let flattenFeatures = flatten(
      features.map((feature) => feature?.details || []),
    );
    flattenFeatures = flatten(
      flattenFeatures.map((feature: any) =>
        feature?.details?.length ? feature?.details : feature,
      ),
    );
    return (
      flattenFeatures.find((feature: any) => feature?.detail_id === id) || null
    );
  } catch (err) {
    return null;
  }
};

export function roundUp(value: number) {
  // eslint-disable-next-line no-unused-vars
  const [intPart, decimalPart] = value.toString().split('.');
  if (!decimalPart || decimalPart.length < 3) {
    return value;
  }
  if (parseInt(decimalPart.charAt(2), 10) >= 5) {
    return Math.ceil(value * 100) / 100;
  }
  return Math.trunc(value * 100) / 100;
}

export const isFeatureEnabled = (
  features: IPlanFeature[],
  id: string,
  index: number,
) => {
  const valueId = `plan_${index}_value` as keyof IPlanFeatureDetail;
  const featureDetail = getFeatureById(features, id) as IPlanFeatureDetail;
  return isValueTrue(featureDetail?.[valueId]);
};

export const getActivatedFeatures = (
  features: IPlanFeature[],
  index: number,
  planId: string,
) => {
  const valueId = `plan_${index}_value` as keyof IPlanFeatureDetail;
  const access_dny = getFeatureById(features, 'access_dny');
  const prom_adm = getFeatureById(features, 'prom_adm');
  const block_usr = getFeatureById(features, 'block_usr');
  const power_usr = getFeatureById(features, 'power_usr');
  const guest_usr = getFeatureById(features, 'guest_usr');
  const allow_dev = getFeatureById(features, 'allow_dev');
  const paus_syn = getFeatureById(features, 'paus_syn');
  const wipe_rmt = getFeatureById(features, 'wipe_rmt');
  const perm_itm = getFeatureById(features, 'perm_itm');
  const keep_rmt = getFeatureById(features, 'keep_rmt');
  const lock_key = getFeatureById(features, 'lock_key');
  const audit_log = getFeatureById(features, 'audit_log');
  const pdf_edt = getFeatureById(features, 'pdf_edt');
  const share_wit = getFeatureById(features, 'share_wit');
  const hw_tkn = getFeatureById(features, 'hw_tkn');
  const nas_syn = getFeatureById(features, 'nas_syn');
  const vip_sup = getFeatureById(features, 'vip_sup');

  const access_dny_feat = isValueTrue(access_dny?.[valueId]);
  const prom_adm_feat = isValueTrue(prom_adm?.[valueId]);
  const block_usr_feat = isValueTrue(block_usr?.[valueId]);
  const power_usr_feat = isValueTrue(power_usr?.[valueId]);
  const guest_usr_feat = isValueTrue(guest_usr?.[valueId]);
  const allow_dev_feat = isValueTrue(allow_dev?.[valueId]);
  const paus_syn_feat = isValueTrue(paus_syn?.[valueId]);
  const wipe_rmt_feat = isValueTrue(wipe_rmt?.[valueId]);
  const perm_itm_feat = isValueTrue(perm_itm?.[valueId]);
  const keep_rmt_feat = isValueTrue(keep_rmt?.[valueId]);
  const lock_key_feat = isValueTrue(lock_key?.[valueId]);
  const audit_log_feat = isValueTrue(audit_log?.[valueId]);
  const pdf_edt_feat = isValueTrue(pdf_edt?.[valueId]);
  const share_wit_feat = isValueTrue(share_wit?.[valueId]);
  const hw_tkn_feat = isValueTrue(hw_tkn?.[valueId]);
  const nas_syn_feat = isValueTrue(nas_syn?.[valueId]);
  const vip_sup_feat = isValueTrue(vip_sup?.[valueId]);
  const activatedFeatures = [];

  if (planId === 'cluster') {
    if (access_dny_feat) activatedFeatures.push(access_dny);
    if (prom_adm_feat) activatedFeatures.push(prom_adm);
    if (block_usr_feat) activatedFeatures.push(block_usr);
    if (power_usr_feat) activatedFeatures.push(power_usr);
    if (guest_usr_feat) activatedFeatures.push(guest_usr);
    if (allow_dev_feat) activatedFeatures.push(allow_dev);
    if (paus_syn_feat) activatedFeatures.push(paus_syn);
    if (wipe_rmt_feat) activatedFeatures.push(wipe_rmt);
    if (perm_itm_feat) activatedFeatures.push(perm_itm);
    if (keep_rmt_feat) activatedFeatures.push(keep_rmt);
    if (lock_key_feat) activatedFeatures.push(lock_key);
    if (audit_log_feat) activatedFeatures.push(audit_log);
    if (share_wit_feat) activatedFeatures.push(share_wit);
    if (hw_tkn_feat) activatedFeatures.push(hw_tkn);
    if (nas_syn_feat) activatedFeatures.push(nas_syn);
    if (pdf_edt_feat) activatedFeatures.push(pdf_edt);
    if (vip_sup_feat) activatedFeatures.push(vip_sup);
  } else if (planId === 'team') {
    if (power_usr_feat) activatedFeatures.push(power_usr);
    if (guest_usr_feat) activatedFeatures.push(guest_usr);
    if (paus_syn_feat) activatedFeatures.push(paus_syn);
    if (wipe_rmt_feat) activatedFeatures.push(wipe_rmt);
    if (keep_rmt_feat) activatedFeatures.push(keep_rmt);
    if (audit_log_feat) activatedFeatures.push(audit_log);
    if (pdf_edt_feat) activatedFeatures.push(pdf_edt);
    if (share_wit_feat) activatedFeatures.push(share_wit);
    if (vip_sup_feat) activatedFeatures.push(vip_sup);
  } else if (planId === 'business') {
    if (pdf_edt_feat) activatedFeatures.push(pdf_edt);
  }
  return activatedFeatures;
};

export function formatNumber(num: string | number) {
  let numStr = String(num);
  if (numStr.includes('.')) {
    let [whole, decimal] = numStr.split('.');
    if (decimal.length >= 2) {
      return `${whole}.${decimal.slice(0, 2)}`;
    }
    if (decimal.length === 1) {
      return `${whole}.${decimal}0`;
    }
  }
  return `${numStr}.00`;
}

export const calculatePlan = (
  features: IPlanFeature[],
  index: number,
  id: string,
  smsPlans: ISMSPlan[],
  smsAddons: ISMSPlanDetail[],
  selectedPriceOption?: SelectedPriceOption,
) => {
  const selectedItem = smsAddons?.find((i) => i.plan_id === id);
  if (id === 'personal') {
    return {
      monthly_price: '0.00',
      yearly_price: '0.00',
    };
  }
  const correctPDFVal =
    selectedPriceOption?.[(index - 1) as 1 | 2 | 3]?.price ||
    (selectedPriceOption as unknown as PDFLicense)?.price ||
    '0';
  const pdfPrice = parseFloat(correctPDFVal);
  const currentPlan = smsPlans[index - 1];
  const currentPlanYearlyPrice = parseFloat(currentPlan.yearly_price);
  const currentPlanMonthlyFactor = parseFloat(currentPlan.monthly_factor);
  const pdfMonthlyPrice = pdfPrice * currentPlanMonthlyFactor;
  const currentPlanMonthlyPrice =
    currentPlanYearlyPrice * currentPlanMonthlyFactor;

  const activatedFeatures = getActivatedFeatures(features, index, id);
  const featuresToFilter = ['pdf_edt', 'vip_sup'];
  const correctFeatures = activatedFeatures.filter(
    (feature) => !featuresToFilter.includes(feature.detail_id),
  );
  const filteredFeatures = activatedFeatures.filter((feature) =>
    featuresToFilter.includes(feature.detail_id),
  );
  const hasPrioritySupport = filteredFeatures.some(
    (f) => f.detail_id === 'vip_sup',
  );
  const hasPdfEditor = filteredFeatures.some((f) => f.detail_id === 'pdf_edt');

  if (id === 'business') {
    let floatPrice = currentPlanMonthlyPrice;
    if (hasPdfEditor) {
      floatPrice += pdfMonthlyPrice;
    }

    const monthlyPrice = floatPrice;
    const yearlyPrice = floatPrice / currentPlanMonthlyFactor;

    return {
      monthly_price: formatNumber(monthlyPrice),
      yearly_price: formatNumber(yearlyPrice),
    };
  }

  if (!selectedItem) {
    return {
      monthly_price: '0.00',
      yearly_price: '0.00',
    };
  }

  const selectedItems = selectedItem.items.slice();
  selectedItems.unshift({
    number_of_addons: 0,
    cumulative_price: '0.00',
    next_item_price: '0.50',
  });

  const totalCorrectFeatures = correctFeatures.length || 0;
  const pricingBasedOnAllAddons = selectedItems.find(
    (i) => i.number_of_addons === totalCorrectFeatures,
  );
  if (!pricingBasedOnAllAddons) {
    return {
      monthly_price: '0.00',
      yearly_price: '0.00',
    };
  }

  const PRIORITY_SUPPORT_MULTIPLIER = parseFloat(currentPlan.support_factor);
  const newAddOnPricing = pricingBasedOnAllAddons.cumulative_price || '0.00';
  let floatPrice = currentPlanMonthlyPrice + parseFloat(newAddOnPricing);
  if (hasPrioritySupport) {
    floatPrice += PRIORITY_SUPPORT_MULTIPLIER * floatPrice;
  }
  if (hasPdfEditor) {
    floatPrice += pdfMonthlyPrice;
  }
  const monthlyPrice = floatPrice;
  const yearlyPrice = floatPrice / currentPlanMonthlyFactor;

  return {
    monthly_price: formatNumber(monthlyPrice),
    yearly_price: formatNumber(yearlyPrice),
  };
};

export const getIndexesOfFeature = (features: IPlanFeature[], id: string) => {
  let i1: number = 0;
  let i2: number = 0;
  let i3: number = -1;
  features.map((feature, idx1) =>
    feature?.details?.map((detail, idx2) => {
      if (detail?.detail_id === id) {
        i1 = idx1;
        i2 = idx2;
      } else if (detail?.details?.length) {
        detail?.details?.map((d: any, idx3: number) => {
          if (d?.detail_id === id) {
            i1 = idx1;
            i2 = idx2;
            i3 = idx3;
          }
        });
      }
    }),
  );
  return [i1, i2, i3];
};

export const calculateEnabledFeaturesAndPlan = (
  enabledFeatures: Array<keyof typeof testPlans.team>,
  features: { plan_features: IPlanFeature[]; pricing_plans: IPlan[] },
  id: string,
  smsPlans: ISMSPlan[] = DEFAULT_PLANS as ISMSPlan[],
  smsAddons: ISMSPlanDetail[] = SMS_PLANS_ADDONS as ISMSPlanDetail[],
) => {
  const tempFeatures = features.plan_features;
  const indexOfPlan = features.pricing_plans.findIndex(
    (plan) => plan.plan_id === id,
  );

  enabledFeatures.map((feature) => {
    const indexesOfItem = getIndexesOfFeature(tempFeatures, feature);
    const valueKey =
      `plan_${indexOfPlan + 1}_value` as keyof IPlanFeatureDetail;
    tempFeatures[indexesOfItem[0]].details[indexesOfItem[1]][valueKey] = true;
  });
  return calculatePlan(
    tempFeatures,
    (indexOfPlan + 1) as 1 | 2 | 3 | 4,
    id || 'personal',
    smsPlans,
    smsAddons,
  );
};

export const parseQuery = () => {
  const parsedString = window.location.search.substring(1).split('&');
  let parsedQuery: { [key: string]: string } = {};
  parsedString.map((item) => {
    const key = item.split('=')[0];
    let value = item.split('=')[1];
    if (key === 'email' || key?.toLowerCase?.().includes?.('email')) {
      try {
        value = decodeURIComponent(value);
      } catch (err) {
        value = value || '';
      }
    }
    parsedQuery = { ...parsedQuery, [`${key}`]: value };
  });
  return parsedQuery;
};

export const languageOptions = ['en', 'de', 'es', 'fr', 'it', 'pt', 'ru'];
export const ZOOM_FACTOR = 0.1;
export const sizes = { w: 20, h: 20, viewBox: '0 0 20 20' };

export const encodeQuery = async (body = {}) => {
  const res = await axios.post('/api/encode-query-params', body);
  return res?.data?.query || '';
};

export const decodeQuery = async (query: any) => {
  const res = await axios.post('/api/decode-query-params', { query });
  return res?.data || {};
};

export const validateToken = async (_token = '') => {
  let token: string | IToken = _token;
  try {
    token = JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
  if ((token as IToken)?.exp) {
    const { exp } = token as IToken;
    // @ts-ignore
    if (new Date() > exp * 1000) {
      return false;
    }
    try {
      return await validateUser(_token);
    } catch (err) {
      return false;
    }
  }
  return false;
};

export const parseLinkAndCodeFromEmail = (body = '') => {
  const res = {
    oneTimeCode: '',
    magicLink: '',
  };
  const regex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gim;
  const allMatchedURLs = body.match(regex);
  if (allMatchedURLs?.length) {
    const magicLinkURL = allMatchedURLs
      .filter(
        (url) =>
          url?.toLowerCase?.()?.includes?.('.eisst.com') ||
          url?.toLowerCase?.()?.includes?.('.quodarca.net') ||
          url?.toLowerCase?.()?.includes?.('.quodarca.com'),
      )?.[0]
      ?.split('&amp')
      ?.join('');
    if (magicLinkURL?.length) {
      const indexOfURL = body.indexOf(magicLinkURL);
      const indexOfSpaceAfterURL = body.indexOf(' ', indexOfURL);
      const fullLink = body.substring(indexOfURL, indexOfSpaceAfterURL);
      if (fullLink.match(/\d{6}/)) {
        res.oneTimeCode = fullLink.match(/\d{6}/)?.[0] || '';
      } else {
        res.oneTimeCode = fullLink.split('token=')[1]?.split?.(`"`)?.join?.('');
      }
      res.magicLink = fullLink
        .split('&amp;')
        .join('&')
        .split?.(`"`)
        ?.join?.('');
    }
  }
  return res;
};

export function extractHttpsLinks(str: string) {
  const regex = /https:\/\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]+/g;
  const matches = str.match(regex)?.map((res) => res.split(')').join(''));
  return matches || [];
}

export const matchTokens = (body = '', text = '', html = '') => {
  const regex = /^[a-zA-Z0-9_-]+$/;
  if (regex.test(text)) {
    return text;
  }
  if (regex.test(html)) {
    return html;
  }
  if (regex.test(body)) {
    return body;
  }
  const regex2 = /data-code="(\d+)"|data-code="([a-zA-Z0-9_-]+)"/;
  const match4 = body.match(regex2);
  const match5 = text.match(regex2);
  const match6 = html.match(regex2);
  if (match4 && match4.length >= 3) {
    return match4[1] || match4[2];
  }
  if (match5 && match5.length >= 3) {
    return match5[1] || match5[2];
  }
  if (match6 && match6.length >= 3) {
    return match6[1] || match6[2];
  }
  if (regex.test(html.replace(/<\/?p>/gi, ''))) {
    return html.replace(/<\/?p>/gi, '');
  }
  const regex1 = />(\d+)<\/div>/;
  const match1 = body.match(regex1);
  const match2 = text.match(regex1);
  const match3 = html.match(regex1);
  if (match1 && match1.length >= 2) {
    return match1[1];
  }
  if (match2 && match2.length >= 2) {
    return match2[1];
  }
  if (match3 && match3.length >= 2) {
    return match3[1];
  }

  return '';
};

export const matchRestOfElements = (_text = '') => {
  let text = _text;
  const regex = /(\w+)=([^=\n]+)/g;
  const result: any = {};

  let match;
  let i = 0;
  while ((match = regex.exec(text))) {
    result[match[1]] = decodeURIComponent(match[2].split('\r').join(''));
    i += 1;
    if (i > 100) {
      break;
    }
  }
  return result;
};

export const parseLinkAndTokenFromEmail = (
  _body = '',
  _text = '',
  _html = '',
  subject = '',
) => {
  const res = {
    oneTimeCode: '',
    magicLink: '',
    token: '',
  };
  let body = _body.split('&amp;').join('&');
  let html = _html.split('&amp;').join('&');
  const allMatchedURLs = extractHttpsLinks(html);
  const allMatchedTokens = matchTokens(body, _text, _html);
  const restMatches = matchRestOfElements(_text);
  if (allMatchedURLs?.length) {
    const magicLinkURLs = allMatchedURLs.filter((url) => {
      let lowerCaseURL = url?.toLowerCase?.();
      return (
        !lowerCaseURL?.includes('ios-and-android') &&
        !lowerCaseURL?.includes('digitalocean') &&
        !lowerCaseURL?.includes('mac-and-windows') &&
        (lowerCaseURL?.includes('token=') ||
          lowerCaseURL?.includes('code=') ||
          lowerCaseURL?.includes?.('.quodarca.com') ||
          lowerCaseURL?.includes?.('.quodarca.net') ||
          lowerCaseURL?.includes?.('.eisst.com'))
      );
    });
    magicLinkURLs.sort((a, b) => {
      if (a?.toLowerCase?.()?.includes?.('backend.quodarca.net')) {
        return -1;
      }
      if (b?.toLowerCase?.()?.includes?.('backend.quodarca.net')) {
        return 1;
      }
      return 0;
    });
    magicLinkURLs.sort((a, b) => {
      if (a?.toLowerCase?.()?.includes?.('token=')) {
        return -1;
      }
      if (b?.toLowerCase?.()?.includes?.('token=')) {
        return 1;
      }
      return 0;
    });
    magicLinkURLs.sort((a, b) => {
      if (a?.toLowerCase?.()?.includes?.('action/')) {
        return -1;
      }
      if (b?.toLowerCase?.()?.includes?.('action/')) {
        return 1;
      }
      return 0;
    });
    const magicLinkURL = magicLinkURLs?.[0]?.split('&amp')?.join('');
    if (magicLinkURL?.length) {
      const indexOfURL = body.indexOf(magicLinkURL);
      let indexOfSpaceAfterURL = body.indexOf(')', indexOfURL);
      if (indexOfSpaceAfterURL === -1) {
        indexOfSpaceAfterURL = body.indexOf(' ', indexOfURL);
      }
      const fullLink = body.substring(indexOfURL, indexOfSpaceAfterURL);
      if (fullLink.match(/\d{6}/)) {
        res.oneTimeCode = fullLink.match(/\d{6}/)?.[0] || '';
      } else {
        res.oneTimeCode = fullLink.split('token=')[1]?.split?.(`"`)?.join?.('');
        res.token = res.oneTimeCode;
      }
      res.magicLink = magicLinkURL;
    }
  }
  if (allMatchedTokens?.length && !res.oneTimeCode) {
    res.oneTimeCode = allMatchedTokens;
  }
  if (restMatches?.token && !res.oneTimeCode) {
    res.oneTimeCode = restMatches.token;
  }
  if (restMatches?.magicLink && !res.magicLink) {
    res.magicLink = restMatches.magicLink;
  }
  const verificationCodeMatch = _text.match(/\b\d{6}\b/);
  if (verificationCodeMatch && !res.token) {
    res.oneTimeCode = verificationCodeMatch[0];
  }

  const verificationCodeMatch2 = _text.match(
    /(?<=Verification Code:\r\n\s*)\b\d{6}\b/,
  );
  if (verificationCodeMatch2) {
    res.oneTimeCode = verificationCodeMatch2[0];
  }

  const oneTimeCodeRegex =
    /<div style="margin-top: 8px; text-align: center; font-size: 30px; font-weight: 700; line-height: 40px; color: #030303; font-family: Arial, Helvetica, sans-serif">(\d{6})<\/div>/;
  const oneTimeCodeMatch = body.match(oneTimeCodeRegex);
  if (oneTimeCodeMatch) {
    res.oneTimeCode = oneTimeCodeMatch[1];
  }

  const specificLinkRegex =
    /https:\/\/website\.quodarca\.com\/sms\/welcome-login\.html\?token=\d+&email=[^"]+/;
  const specificLinkMatch = body.match(specificLinkRegex);
  if (specificLinkMatch) {
    res.magicLink = specificLinkMatch[0];
  }
  const alphanumericRegex = /<strong>([a-zA-Z0-9]{7,})<\/strong>/;
  const alphanumericMatch = _html.match(alphanumericRegex);
  if (alphanumericMatch) {
    res.token = alphanumericMatch[1];
  }

  const allMatchedTokens2 = matchTokens(body, _text, _html);

  if (allMatchedTokens2?.length) {
    res.oneTimeCode = allMatchedTokens2;
    if (!res.token) {
      res.token = allMatchedTokens2;
    }
  }

  const invitationCodeRegex =
    /id="invitation-code" data-invitation-code="(\d{6})"/;
  const invitationCodeMatch = _html.match(invitationCodeRegex);
  if (invitationCodeMatch) {
    res.oneTimeCode = invitationCodeMatch[1];
  }

  const unlockCodeRegexWithStyle =
    /<span style="color:unlock_authcode;"><strong>(\d{6})<\/strong><\/span>/;
  const unlockCodeMatchWithStyle = _html.match(unlockCodeRegexWithStyle);
  if (unlockCodeMatchWithStyle?.[1]) {
    res.oneTimeCode = unlockCodeMatchWithStyle[1];
  } else if (subject === 'QuodArca unlock request') {
    const unlockCodeRegex = /Unlock Code:\r\n\s*(\d{6})/;
    const unlockCodeMatch = _text.match(unlockCodeRegex);
    if (unlockCodeMatch?.[1]) {
      res.oneTimeCode = unlockCodeMatch[1];
    }
  }

  return res;
};

export const parseDeepLinkFromEmail = (body = '') => {
  const res = {
    magicLink: '',
  };
  const regex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gim;
  const allMatchedURLs = body.match(regex);
  if (allMatchedURLs?.length) {
    const magicLinkURL = allMatchedURLs
      .filter((url) => url.toLowerCase().includes('backend.'))[0]
      .split('&amp')
      .join('');
    if (magicLinkURL.length) {
      const indexOfURL = body.indexOf(magicLinkURL);
      const indexOfSpaceAfterURL = body.indexOf(' ', indexOfURL);
      const fullLink = body.substring(indexOfURL, indexOfSpaceAfterURL);
      res.magicLink = fullLink
        .split('&amp;')
        .join('&')
        .split?.(`"`)
        ?.join?.('');
    }
  }
  return res;
};

export const getTokenFromEmail = async (email = '') => {
  let out = {
    oneTimeCode: '',
    magicLink: '',
  };
  const login = email.split('@')[0];
  const domain = email.split('@')[1];
  const res = await axios
    .get(
      `https://www.1secmail.com/api/v1` +
        `/?action=getMessages&login=${login}&domain=${domain}`,
    )
    .then((response) => response.data)
    .catch((err) => err);
  if (res?.length) {
    const res2 = await axios
      .get(
        `https://www.1secmail.com/api/v1` +
          `/?action=readMessage&login=${login}&domain=${domain}&id=${res?.[0]?.id}`,
      )
      .then((response) => response.data)
      .catch((err) => err);
    out = parseLinkAndCodeFromEmail(res2.body);
  }
  return out;
};

export const getLinkFromMail = async (email = '') => {
  let out = {
    magicLink: '',
  };
  const login = email.split('@')[0];
  const domain = email.split('@')[1];
  const res = await axios
    .get(
      `https://www.1secmail.com/api/v1` +
        `/?action=getMessages&login=${login}&domain=${domain}`,
    )
    .then((response) => response.data)
    .catch((err) => err);
  if (res?.length) {
    const res2 = await axios
      .get(
        `https://www.1secmail.com/api/v1` +
          `/?action=readMessage&login=${login}&domain=${domain}&id=${res?.[0]?.id}`,
      )
      .then((response) => response.data)
      .catch((err) => err);
    out = parseDeepLinkFromEmail(res2.body);
  }
  return out.magicLink;
};

export const trimString = (str: string) =>
  str.trim().toLowerCase().split('-').join('').split('_').join('');

export const wait = (ms: number, value?: any) =>
  new Promise((resolve) => setTimeout(resolve, ms, value));

export const toBoolean = (value: boolean | string | unknown = 'false') => {
  try {
    if (value === 'coming_soon') return false;
    if (value === 'contact_sales') return false;
    if (value === true || value === false) {
      return value;
    }
    if (typeof value === 'string') return value?.toLowerCase?.() === 'true';

    return (value as string)?.toString?.().toLowerCase?.() === 'true';
  } catch (err) {
    return false;
  }
};

export const getFullPlanDetails = (
  plan: any,
  currentFeatures: IPlanFeature[],
  pricing_plans: any[],
  recursion: string,
  custom = false,
  locale = 'en',
) => {
  try {
    const fullPlan = pricing_plans.filter(
      (p: any) => p.plan_id === plan.plan_id,
    )[0];
    const idx = pricing_plans.indexOf(fullPlan) + 1 || 2;
    const access_dny_feat = getFeatureById(currentFeatures, 'access_dny')[
      `plan_${idx}_value`
    ];
    const prom_adm_feat = getFeatureById(currentFeatures, 'prom_adm')[
      `plan_${idx}_value`
    ];
    const block_usr_feat = getFeatureById(currentFeatures, 'block_usr')[
      `plan_${idx}_value`
    ];
    const power_usr_feat = getFeatureById(currentFeatures, 'power_usr')[
      `plan_${idx}_value`
    ];
    const guest_usr_feat = getFeatureById(currentFeatures, 'guest_usr')[
      `plan_${idx}_value`
    ];
    const allow_dev_feat = getFeatureById(currentFeatures, 'allow_dev')[
      `plan_${idx}_value`
    ];
    const paus_syn_feat = getFeatureById(currentFeatures, 'paus_syn')[
      `plan_${idx}_value`
    ];
    const wipe_rmt_feat = getFeatureById(currentFeatures, 'wipe_rmt')[
      `plan_${idx}_value`
    ];
    const perm_itm_feat = getFeatureById(currentFeatures, 'perm_itm')[
      `plan_${idx}_value`
    ];
    const keep_rmt_feat = getFeatureById(currentFeatures, 'keep_rmt')[
      `plan_${idx}_value`
    ];
    const lock_key_feat = getFeatureById(currentFeatures, 'lock_key')[
      `plan_${idx}_value`
    ];
    const audit_log_feat = getFeatureById(currentFeatures, 'audit_log')[
      `plan_${idx}_value`
    ];
    const pdf_ann_feat = getFeatureById(currentFeatures, 'pdf_ann')[
      `plan_${idx}_value`
    ];
    const pdf_edt_feat = getFeatureById(currentFeatures, 'pdf_edt')[
      `plan_${idx}_value`
    ];
    const pdf_sig_feat = getFeatureById(currentFeatures, 'pdf_sig')[
      `plan_${idx}_value`
    ];
    const share_wit_feat = getFeatureById(currentFeatures, 'share_wit')[
      `plan_${idx}_value`
    ];
    const hw_tkn_feat = getFeatureById(currentFeatures, 'hw_tkn')[
      `plan_${idx}_value`
    ];
    const nas_syn_feat = getFeatureById(currentFeatures, 'nas_syn')[
      `plan_${idx}_value`
    ];
    const vip_sup_feat = getFeatureById(currentFeatures, 'vip_sup')[
      `plan_${idx}_value`
    ];
    const inv_usr_feat = getFeatureById(currentFeatures, 'inv_usr')[
      `plan_${idx}_value`
    ];
    const del_usr_feat = getFeatureById(currentFeatures, 'del_usr')[
      `plan_${idx}_value`
    ];
    const activ_dev_feat = getFeatureById(currentFeatures, 'activ_dev')[
      `plan_${idx}_value`
    ];

    return {
      plan_id: plan.plan_id,
      recursion,
      price: recursion === 'monthly' ? plan.monthly_price : plan.yearly_price,
      other_price:
        recursion === 'monthly' ? plan.yearly_price : plan.monthly_price,
      custom,
      locale,
      access_dny: isValueTrue(access_dny_feat),
      prom_adm: isValueTrue(prom_adm_feat),
      block_usr: isValueTrue(block_usr_feat),
      power_usr: isValueTrue(power_usr_feat),
      guest_usr: isValueTrue(guest_usr_feat),
      allow_dev: isValueTrue(allow_dev_feat),
      paus_syn: isValueTrue(paus_syn_feat),
      wipe_rmt: isValueTrue(wipe_rmt_feat),
      perm_itm: isValueTrue(perm_itm_feat),
      keep_rmt: isValueTrue(keep_rmt_feat),
      lock_key: isValueTrue(lock_key_feat),
      audit_log: isValueTrue(audit_log_feat),
      pdf_ann: isValueTrue(pdf_ann_feat),
      pdf_sig: isValueTrue(pdf_sig_feat),
      share_wit: isValueTrue(share_wit_feat),
      hw_tkn: isValueTrue(hw_tkn_feat),
      nas_syn: isValueTrue(nas_syn_feat),
      vip_sup: isValueTrue(vip_sup_feat),
      inv_usr: isValueTrue(inv_usr_feat),
      del_usr: isValueTrue(del_usr_feat),
      activ_dev: isValueTrue(activ_dev_feat),
      ...(pdf_edt_feat ? { pdf_edt: isValueTrue(pdf_edt_feat) } : {}),
    };
  } catch (err) {
    return false;
  }
};

export const enableFeaturesForSurrondingPlans = (
  plansFeatures: IPlanFeature[],
  previouslySelectedPlan: number,
  newSelectedPlan: number,
) => {
  if (!plansFeatures?.length || previouslySelectedPlan < 0) {
    return null;
  }

  const allFeatures = [
    getFeatureById(plansFeatures, 'access_dny'),
    getFeatureById(plansFeatures, 'prom_adm'),
    getFeatureById(plansFeatures, 'block_usr'),
    getFeatureById(plansFeatures, 'power_usr'),
    getFeatureById(plansFeatures, 'guest_usr'),
    getFeatureById(plansFeatures, 'allow_dev'),
    getFeatureById(plansFeatures, 'paus_syn'),
    getFeatureById(plansFeatures, 'wipe_rmt'),
    getFeatureById(plansFeatures, 'perm_itm'),
    getFeatureById(plansFeatures, 'keep_rmt'),
    getFeatureById(plansFeatures, 'lock_key'),
    getFeatureById(plansFeatures, 'audit_log'),
    getFeatureById(plansFeatures, 'pdf_ann'),
    getFeatureById(plansFeatures, 'pdf_edt'),
    getFeatureById(plansFeatures, 'share_wit'),
    getFeatureById(plansFeatures, 'hw_tkn'),
    getFeatureById(plansFeatures, 'nas_syn'),
    getFeatureById(plansFeatures, 'vip_sup'),
  ];
  const tempCopy = JSON.parse(
    JSON.stringify([...plansFeatures]),
  ) as IPlanFeature[];
  allFeatures.map((feature) => {
    const valueKey =
      `plan_${previouslySelectedPlan + 1}_value` as keyof IPlanFeatureDetail;
    const indexesOfFeature = getIndexesOfFeature(
      plansFeatures,
      feature.detail_id,
    );
    const valueOfFeature = feature[valueKey];
    if (isValueTrue(valueOfFeature)) {
      const valueKey2 =
        `plan_${newSelectedPlan}_value` as keyof IPlanFeatureDetail;
      const isEditableKey =
        `is_editable_for_plan_${newSelectedPlan}` as keyof IPlanFeatureDetail;

      let selectedElement =
        tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]];
      if (indexesOfFeature?.[2] > -1) {
        selectedElement =
          tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]].details[
            indexesOfFeature[2]
          ];
      }
      const isEditable = selectedElement[isEditableKey];
      if (isEditable) {
        if (indexesOfFeature?.[2] > -1) {
          tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]].details[
            indexesOfFeature[2]
          ][valueKey2] =
            tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]].details[
              indexesOfFeature[2]
            ][valueKey];
        } else {
          tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]][
            valueKey2
          ] =
            tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]][
              valueKey
            ];
        }
      }
    }
  });
  return tempCopy;
};

export const getFullDefaultPlanDetails = async (plan: any) => {
  try {
    const { plan_features: currentFeatures, pricing_plans } = await axios
      .post('/api/pricing-table', {
        locale: 'en',
      })
      .then((res) => res.data)
      .catch((err) => err);
    const fullPlan = pricing_plans.filter(
      (p: any) => p.plan_id === plan.plan_id,
    )[0];
    const idx = pricing_plans.indexOf(fullPlan) + 1 || 2;
    const access_dny_feat = getFeatureById(currentFeatures, 'access_dny')[
      `plan_${idx}_value`
    ];
    const prom_adm_feat = getFeatureById(currentFeatures, 'prom_adm')[
      `plan_${idx}_value`
    ];
    const block_usr_feat = getFeatureById(currentFeatures, 'block_usr')[
      `plan_${idx}_value`
    ];
    const power_usr_feat = getFeatureById(currentFeatures, 'power_usr')[
      `plan_${idx}_value`
    ];
    const guest_usr_feat = getFeatureById(currentFeatures, 'guest_usr')[
      `plan_${idx}_value`
    ];
    const allow_dev_feat = getFeatureById(currentFeatures, 'allow_dev')[
      `plan_${idx}_value`
    ];
    const paus_syn_feat = getFeatureById(currentFeatures, 'paus_syn')[
      `plan_${idx}_value`
    ];
    const wipe_rmt_feat = getFeatureById(currentFeatures, 'wipe_rmt')[
      `plan_${idx}_value`
    ];
    const perm_itm_feat = getFeatureById(currentFeatures, 'perm_itm')[
      `plan_${idx}_value`
    ];
    const keep_rmt_feat = getFeatureById(currentFeatures, 'keep_rmt')[
      `plan_${idx}_value`
    ];
    const lock_key_feat = getFeatureById(currentFeatures, 'lock_key')[
      `plan_${idx}_value`
    ];
    const audit_log_feat = getFeatureById(currentFeatures, 'audit_log')[
      `plan_${idx}_value`
    ];
    const pdf_ann_feat = getFeatureById(currentFeatures, 'pdf_ann')[
      `plan_${idx}_value`
    ];
    const pdf_edt_feat = getFeatureById(currentFeatures, 'pdf_edt')[
      `plan_${idx}_value`
    ];
    const pdf_sig_feat = getFeatureById(currentFeatures, 'pdf_sig')[
      `plan_${idx}_value`
    ];
    const share_wit_feat = getFeatureById(currentFeatures, 'share_wit')[
      `plan_${idx}_value`
    ];
    const hw_tkn_feat = getFeatureById(currentFeatures, 'hw_tkn')[
      `plan_${idx}_value`
    ];
    const nas_syn_feat = getFeatureById(currentFeatures, 'nas_syn')[
      `plan_${idx}_value`
    ];
    const vip_sup_feat = getFeatureById(currentFeatures, 'vip_sup')[
      `plan_${idx}_value`
    ];
    const inv_usr_feat = getFeatureById(currentFeatures, 'inv_usr')[
      `plan_${idx}_value`
    ];
    const del_usr_feat = getFeatureById(currentFeatures, 'del_usr')[
      `plan_${idx}_value`
    ];
    const activ_dev_feat = getFeatureById(currentFeatures, 'activ_dev')[
      `plan_${idx}_value`
    ];
    const access_dny = plan.access_dny || access_dny_feat;
    const prom_adm = plan.prom_adm || prom_adm_feat;
    const block_usr = plan.block_usr || block_usr_feat;
    const power_usr = plan.power_usr || power_usr_feat;
    const guest_usr = plan.guest_usr || guest_usr_feat;
    const allow_dev = plan.allow_dev || allow_dev_feat;
    const paus_syn = plan.paus_syn || paus_syn_feat;
    const wipe_rmt = plan.wipe_rmt || wipe_rmt_feat;
    const perm_itm = plan.perm_itm || perm_itm_feat;
    const keep_rmt = plan.keep_rmt || keep_rmt_feat;
    const lock_key = plan.lock_key || lock_key_feat;
    const audit_log = plan.audit_log || audit_log_feat;
    const pdf_ann = plan.pdf_ann || pdf_ann_feat;
    const pdf_edt = plan.pdf_edt || pdf_edt_feat;
    const pdf_sig = plan.pdf_sig || pdf_sig_feat;
    const share_wit = plan.share_wit || share_wit_feat;
    const hw_tkn = plan.hw_tkn || hw_tkn_feat;
    const nas_syn = plan.nas_syn || nas_syn_feat;
    const vip_sup = plan.vip_sup || vip_sup_feat;
    const inv_usr = plan.inv_usr || inv_usr_feat;
    const del_usr = plan.del_usr || del_usr_feat;
    const activ_dev = plan.activ_dev || activ_dev_feat;
    return {
      ...plan,
      access_dny: isValueTrue(access_dny),
      prom_adm: isValueTrue(prom_adm),
      block_usr: isValueTrue(block_usr),
      power_usr: isValueTrue(power_usr),
      guest_usr: isValueTrue(guest_usr),
      allow_dev: isValueTrue(allow_dev),
      paus_syn: isValueTrue(paus_syn),
      wipe_rmt: isValueTrue(wipe_rmt),
      perm_itm: isValueTrue(perm_itm),
      keep_rmt: isValueTrue(keep_rmt),
      lock_key: isValueTrue(lock_key),
      audit_log: isValueTrue(audit_log),
      pdf_ann: isValueTrue(pdf_ann),
      pdf_sig: isValueTrue(pdf_sig),
      share_wit: isValueTrue(share_wit),
      hw_tkn: isValueTrue(hw_tkn),
      nas_syn: isValueTrue(nas_syn),
      vip_sup: isValueTrue(vip_sup),
      inv_usr: isValueTrue(inv_usr),
      del_usr: isValueTrue(del_usr),
      activ_dev: isValueTrue(activ_dev),
      ...(isValueTrue(pdf_edt) ? { pdf_edt } : {}),
    };
  } catch (err) {
    return null;
  }
};

export function parseDetails(htmlString: string): ParsedDetails {
  const decodedHtmlString = quotedPrintable.decode(htmlString);
  const $ = cheerio.load(decodedHtmlString);

  let details: ParsedDetails = {};

  function extractDetails(text: string) {
    try {
      let splitText = text.split(':').slice(1).join(':').trim();
      if (text.toLowerCase().includes('time')) {
        details.time = splitText;
        details.encodedTime = encodeURIComponent(splitText);
      } else if (text.toLowerCase().includes('location')) {
        details.location = splitText;
      } else if (
        text.toLowerCase().includes('device') &&
        !text.toLowerCase().includes('device types allowed') &&
        !text.toLowerCase().includes('device allowance limit')
      ) {
        details.device = splitText;
      } else if (text.toLowerCase().includes('ip address')) {
        details.ipAddress = splitText;
      } else if (text.toLowerCase().includes('device types allowed')) {
        details.deviceTypesAllowed = splitText;
      } else if (text.toLowerCase().includes('device allowance limit')) {
        details.deviceAllowanceLimit = splitText;
      } else if (text.toLowerCase().includes('subscription')) {
        details.subscription = splitText;
      } else if (text.toLowerCase().includes('quodArca account owner')) {
        details.accountOwner = splitText;
      } else if (text.toLowerCase().includes('browser')) {
        details.browser = splitText;
      } else if (text.toLowerCase().includes('email of the invitee')) {
        details.inviteeEmail = splitText;
      } else if (text.toLowerCase().includes('previous email')) {
        details.previousEmail = splitText;
      } else if (text.toLowerCase().includes('new email')) {
        details.newEmail = splitText;
      } else if (text.toLowerCase().includes('email')) {
        details.email = splitText;
      }

      if (!details.subscription) {
        const regex = /associated with your <strong>([^<]+)<\/strong>/;
        const match = htmlString.match(regex);
        if (match && match.length >= 2) {
          details.subscription = match[1];
        }
      }
      const regex2 =
        /QuodArca Account Owner with the email address <strong>([^<]+)<\/strong>/;
      const match2 = htmlString.match(regex2);
      if (match2 && match2.length >= 2 && !details.qaoEmail) {
        if (match2[1].includes('@')) details.qaoEmail = match2[1];
      }
      const regex3 = /<strong>([^<]+)<\/strong> as QuodArca® Account Owner/;
      const match3 = htmlString.match(regex3);
      if (match3 && match3.length >= 2 && !details.qaoEmail) {
        if (match3[1].includes('@')) details.qaoEmail = match3[1];
      }
      const regex4 =
        /informed regarding your <strong>([^<]+)<\/strong> subscription/;
      const match4 = htmlString.match(regex4);
      if (match4 && match4.length >= 2 && !details.subscription) {
        details.subscription = match4[1];
      }
      const regex5 =
        /your inviter at <strong>([^<]+)<\/strong> before proceeding/;
      const match5 = htmlString.match(regex5);
      if (match5 && match5.length >= 2 && !details.qaoEmail) {
        if (match5[1].includes('@')) details.qaoEmail = match5[1];
      }
      const regex6 = /your invitation to <strong>([^<]+)<\/strong> to join/;
      const match6 = htmlString.match(regex6);
      if (match6 && match6.length >= 2 && !details.inviteeEmail) {
        if (match6[1].includes('@')) details.inviteeEmail = match6[1];
      }
      const regex7 = /Member from <strong>([^<]+)<\/strong> has been/;
      const match7 = htmlString.match(regex7);
      if (match7 && match7.length >= 2 && !details.qaoEmail) {
        if (match7[1].includes('@')) details.qaoEmail = match7[1];
      }
      const regex8 =
        /associated with the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match8 = htmlString.match(regex8);
      if (match8 && match8.length >= 2 && !details.accountName) {
        details.accountName = match8[1];
      }
      const regex9 = /the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match9 = htmlString.match(regex9);
      if (match9 && match9.length >= 2 && !details.accountName) {
        details.accountName = match9[1];
      }
      const regex10 = /<strong>([^<]+)<\/strong>, whom you previously invited,/;
      const match10 = htmlString.match(regex10);
      if (match10 && match10.length >= 2 && !details.inviteeEmail) {
        if (match10[1].includes('@')) details.inviteeEmail = match10[1];
      }
      const regex11 = /join the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match11 = htmlString.match(regex11);
      if (match11 && match11.length >= 2 && !details.accountName) {
        details.accountName = match11[1];
      }
      const regex12 =
        /sync and become a <strong>([^<]+)<\/strong> of the account/;
      const match12 = htmlString.match(regex12);
      if (match12 && match12.length >= 2 && !details.userType) {
        details.userType = match12[1];
      }
      const regex13 =
        /registered with the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match13 = htmlString.match(regex13);
      if (match13 && match13.length >= 2 && !details.accountName) {
        details.accountName = match13[1];
      }
      const regex14 = /deactivated your device at <strong>([^<]+)<\/strong>/;
      const match14 = htmlString.match(regex14);
      if (match14 && match14.length >= 2 && !details.qaoEmail) {
        if (match14[1].includes('@')) details.qaoEmail = match14[1];
      }
      const regex15 =
        /associated with the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match15 = htmlString.match(regex15);
      if (match15 && match15.length >= 2 && !details.accountName) {
        details.accountName = match15[1];
      }
      const regex16 = /device's status at <strong>([^<]+)<\/strong>/;
      const match16 = htmlString.match(regex16);
      if (match16 && match16.length >= 2 && !details.qaoEmail) {
        if (match16[1].includes('@')) details.qaoEmail = match16[1];
      }
      const regex17 = /access the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match17 = htmlString.match(regex17);
      if (match17 && match17.length >= 2 && !details.accountName) {
        details.accountName = match17[1];
      }
      const regex18 =
        /activity for the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match18 = htmlString.match(regex18);
      if (match18 && match18.length >= 2 && !details.accountName) {
        details.accountName = match18[1];
      }
    } catch (err) {
      /* empty */
    }
  }

  $('ul li div').each((index, element) => {
    extractDetails($(element).text());
  });

  $('.green-links')
    .children('div')
    .children('p')
    .each((index, element) => {
      extractDetails($(element).text());
    });

  $('.green-links')
    .children('p')
    .each((index, element) => {
      extractDetails($(element).text());
    });

  $('#reviewDetails')
    .children('p')
    .each((index, element) => {
      extractDetails($(element).text());
    });

  return details;
}

export function deepEqual(array1: any[], array2: any[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i += 1) {
    const obj1 = array1[i];
    const obj2 = array2[i];

    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});

    if (keys1.length !== keys2.length) {
      return false;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (let key of keys1) {
      if (key === 'details') {
        if (!deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      } else if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }
  return true;
}

export const log = (message: string, additionalInfo?: any) => {
  Logger.log(message, additionalInfo);
};

export const warn = (message: string, additionalInfo?: any) => {
  Logger.warn(message, additionalInfo);
};

export const error = (message: string, additionalInfo?: any) => {
  Logger.error(message, additionalInfo);
};

export const getFileFromStrapi = (key = '') =>
  axios
    .get(`/api/file/${key}`)
    .then((res) => res.data)
    .catch((err) => err);
export const stripHTMLTags = (input = '') =>
  input.replace(/<\/?[^>]+(>|$)/g, '');

export const blobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

export const tutorialInteraction = async (
  slug: string,
  reaction: 'like' | 'dislike',
) => {
  try {
    const data = {
      url: window.location.href,
      urlref: document.referrer,
      res: `${window.screen.width}x${window.screen.height}`,
      h: new Date().getHours(),
      m: new Date().getMinutes(),
      s: new Date().getSeconds(),
      slug,
      reaction,
    };

    await axios
      .post('/api/analytics', data)
      .then((res) => res)
      .catch((err) => err);
  } catch (err) {
    /* empty */
  }
};

export const showToast = {
  info: (message: string) => {
    toast.info(message, {
      position: 'top-right',
      autoClose: 5000,
      style: {
        zIndex: 999999999999,
      },
    });
  },
  success: (message: string) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      style: {
        zIndex: 999999999999,
      },
    });
  },
  error: (message: string) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      style: {
        zIndex: 9999999,
      },
    });
  },
};

export const decodeURIComponentSafe = (str: string) => {
  try {
    return decodeURIComponent(str);
  } catch (err) {
    return str;
  }
};

export const encodeURIComponentSafe = (str: string) => {
  try {
    return encodeURIComponent(str);
  } catch (err) {
    return str;
  }
};

export const preparePayloadForFreePlan = (
  data: any,
  locale: string,
  email: string,
  currency: any,
) => {
  const selectedPlan = data?.pricing_plans?.[0];
  const selectedDetail = data?.plan_features;
  const { plan_id } = selectedPlan;
  const access_dny_feat = getFeatureById(selectedDetail, 'access_dny');
  const prom_adm_feat = getFeatureById(selectedDetail, 'prom_adm');
  const block_usr_feat = getFeatureById(selectedDetail, 'block_usr');
  const power_usr_feat = getFeatureById(selectedDetail, 'power_usr');
  const guest_usr_feat = getFeatureById(selectedDetail, 'guest_usr');
  const allow_dev_feat = getFeatureById(selectedDetail, 'allow_dev');
  const paus_syn_feat = getFeatureById(selectedDetail, 'paus_syn');
  const wipe_rmt_feat = getFeatureById(selectedDetail, 'wipe_rmt');
  const perm_itm_feat = getFeatureById(selectedDetail, 'perm_itm');
  const keep_rmt_feat = getFeatureById(selectedDetail, 'keep_rmt');
  const lock_key_feat = getFeatureById(selectedDetail, 'lock_key');
  const audit_log_feat = getFeatureById(selectedDetail, 'audit_log');
  const pdf_ann_feat = getFeatureById(selectedDetail, 'pdf_ann');
  const pdf_edt_feat = getFeatureById(selectedDetail, 'pdf_edt');
  const pdf_sig_feat = getFeatureById(selectedDetail, 'pdf_sig');
  const share_wit_feat = getFeatureById(selectedDetail, 'share_wit');
  const hw_tkn_feat = getFeatureById(selectedDetail, 'hw_tkn');
  const nas_syn_feat = getFeatureById(selectedDetail, 'nas_syn');
  const vip_sup_feat = getFeatureById(selectedDetail, 'vip_sup');

  const plan = {
    plan_id,
    recursion: 'yearly',
    price: 0,
    other_price: 0,
    custom: false,
    locale,
    access_dny: isValueTrue(access_dny_feat.plan_1_value),
    prom_adm: isValueTrue(prom_adm_feat.plan_1_value),
    block_usr: isValueTrue(block_usr_feat.plan_1_value),
    power_usr: isValueTrue(power_usr_feat.plan_1_value),
    guest_usr: isValueTrue(guest_usr_feat.plan_1_value),
    allow_dev: isValueTrue(allow_dev_feat.plan_1_value),
    paus_syn: isValueTrue(paus_syn_feat.plan_1_value),
    wipe_rmt: isValueTrue(wipe_rmt_feat.plan_1_value),
    perm_itm: isValueTrue(perm_itm_feat.plan_1_value),
    keep_rmt: isValueTrue(keep_rmt_feat.plan_1_value),
    lock_key: isValueTrue(lock_key_feat.plan_1_value),
    audit_log: isValueTrue(audit_log_feat.plan_1_value),
    pdf_ann: isValueTrue(pdf_ann_feat.plan_1_value),
    pdf_edt: isValueTrue(pdf_edt_feat.plan_1_value),
    pdf_sig: isValueTrue(pdf_sig_feat.plan_1_value),
    share_wit: isValueTrue(share_wit_feat.plan_1_value),
    hw_tkn: isValueTrue(hw_tkn_feat.plan_1_value),
    nas_syn: isValueTrue(nas_syn_feat.plan_1_value),
    vip_sup: isValueTrue(vip_sup_feat.plan_1_value),
  };
  return {
    email,
    plan,
    source: 'sms',
    currency: currency.code.toUpperCase(),
  };
};

export const checkStorageEvent = (e: StorageEvent, locale: string) => {
  const currentURL = window.location.href?.split('?')?.[0];
  const shouldNotRedirect = ['login', 'signup', 'email'];
  const isAnyRouteExist = shouldNotRedirect.some((item) =>
    currentURL.includes(item),
  );
  if (isAnyRouteExist) return null;
  if (e.key === 'email') {
    if (e.newValue === null) {
      window.location.href = `/${locale}/sms/login`;
    } else if (!!e.oldValue?.length && e.oldValue !== e.newValue) {
      window.location.href = `/${locale}/sms/login`;
    }
  } else if (e.key === 'access' || e.key === 'refresh') {
    if (e.newValue === null) {
      window.location.href = `/${locale}/sms/login`;
    }
  }
};

export const replaceColorInTheme = (
  theme: 'light' | 'dark',
  htmlString: string,
) => {
  const regex = /#46B01C/gi;
  const regex2 = /#429B1E/gi;
  const regex3 = /rgb\(70,\s*176,\s*28\)/gi;
  const regex4 = /rgb\(66,\s*155,\s*30\)/gi;

  let updatedHTMLString = htmlString;
  if (theme === 'dark') {
    updatedHTMLString = updatedHTMLString?.replace?.(regex4, '#46B01C') || '';
    updatedHTMLString = updatedHTMLString?.replace?.(regex2, '#46B01C') || '';
  } else {
    updatedHTMLString = updatedHTMLString?.replace?.(regex, '#429B1E') || '';
    updatedHTMLString = updatedHTMLString?.replace?.(regex3, '#429B1E') || '';
  }
  return updatedHTMLString;
};

export const convertPlanPayload = (_plan: any) => {
  try {
    let plan = _plan;
    Object.keys(_plan || {}).forEach((key) => {
      if (typeof _plan[key] === 'string') {
        if (_plan[key] === 'false') {
          plan[key] = false;
        } else if (isValueTrue(_plan[key])) {
          if (key === 'pdf_edt') {
            plan[key] = _plan[key];
          } else {
            plan[key] = true;
          }
        }
      }
    });
    return plan;
  } catch (err) {
    return {};
  }
};

export const toBool = (val: any) => {
  if (val === 'coming_soon') return false;
  if (val === 'contact_sales') return false;
  return val === true || val === 'true';
};

export const getPlanPayloadDetails = (
  features: any,
  idx: number,
  recursion: string,
) => {
  try {
    const valueId = `plan_${idx}_value`;

    const access_dny_feat = getFeatureById(features, 'access_dny')[valueId];
    const prom_adm_feat = getFeatureById(features, 'prom_adm')[valueId];
    const block_usr_feat = getFeatureById(features, 'block_usr')[valueId];
    const power_usr_feat = getFeatureById(features, 'power_usr')[valueId];
    const guest_usr_feat = getFeatureById(features, 'guest_usr')[valueId];
    const allow_dev_feat = getFeatureById(features, 'allow_dev')[valueId];
    const paus_syn_feat = getFeatureById(features, 'paus_syn')[valueId];
    const wipe_rmt_feat = getFeatureById(features, 'wipe_rmt')[valueId];
    const perm_itm_feat = getFeatureById(features, 'perm_itm')[valueId];
    const keep_rmt_feat = getFeatureById(features, 'keep_rmt')[valueId];
    const lock_key_feat = getFeatureById(features, 'lock_key')[valueId];
    const audit_log_feat = getFeatureById(features, 'audit_log')[valueId];
    const pdf_ann_feat = getFeatureById(features, 'pdf_ann')[valueId];
    const pdf_edt_feat = getFeatureById(features, 'pdf_edt')[valueId];
    const share_wit_feat = getFeatureById(features, 'share_wit')[valueId];
    const hw_tkn_feat = getFeatureById(features, 'hw_tkn')[valueId];
    const nas_syn_feat = getFeatureById(features, 'nas_syn')[valueId];
    const vip_sup_feat = getFeatureById(features, 'vip_sup')[valueId];
    const inv_usr_feat = getFeatureById(features, 'inv_usr')[valueId];
    const del_usr_feat = getFeatureById(features, 'del_usr')[valueId];
    const activ_dev_feat = getFeatureById(features, 'activ_dev')[valueId];
    const pdf_sig_feat = getFeatureById(features, 'pdf_sig')[valueId];
    return {
      recursion,
      access_dny: access_dny_feat === '.' ? false : toBool(access_dny_feat),
      prom_adm: prom_adm_feat === '.' ? false : toBool(prom_adm_feat),
      block_usr: block_usr_feat === '.' ? false : toBool(block_usr_feat),
      power_usr: power_usr_feat === '.' ? false : toBool(power_usr_feat),
      guest_usr: guest_usr_feat === '.' ? false : toBool(guest_usr_feat),
      allow_dev: allow_dev_feat === '.' ? false : toBool(allow_dev_feat),
      paus_syn: paus_syn_feat === '.' ? false : toBool(paus_syn_feat),
      wipe_rmt: wipe_rmt_feat === '.' ? false : toBool(wipe_rmt_feat),
      perm_itm: perm_itm_feat === '.' ? false : toBool(perm_itm_feat),
      keep_rmt: keep_rmt_feat === '.' ? false : toBool(keep_rmt_feat),
      lock_key: lock_key_feat === '.' ? false : toBool(lock_key_feat),
      audit_log: audit_log_feat === '.' ? false : toBool(audit_log_feat),
      pdf_ann: pdf_ann_feat === '.' ? false : toBool(pdf_ann_feat),
      share_wit: share_wit_feat === '.' ? false : toBool(share_wit_feat),
      hw_tkn: hw_tkn_feat === '.' ? false : toBool(hw_tkn_feat),
      nas_syn: nas_syn_feat === '.' ? false : toBool(nas_syn_feat),
      vip_sup: vip_sup_feat === '.' ? false : toBool(vip_sup_feat),
      inv_usr: inv_usr_feat === '.' ? false : toBool(inv_usr_feat),
      del_usr: del_usr_feat === '.' ? false : toBool(del_usr_feat),
      activ_dev: activ_dev_feat === '.' ? false : toBool(activ_dev_feat),
      pdf_sig: pdf_sig_feat === '.' ? false : toBool(pdf_sig_feat),
      ...(isValueTrue(pdf_edt_feat) ? { pdf_edt: pdf_edt_feat } : {}),
    };
  } catch (err) {
    /* empty */
  }
};

export const convertOptionsToFeaturesSafe = (params: any, plansData: any) => {
  try {
    const {
      plan_id,
      recursion,
      access_dny,
      prom_adm,
      block_usr,
      power_usr,
      guest_usr,
      allow_dev,
      paus_syn,
      wipe_rmt,
      perm_itm,
      keep_rmt,
      lock_key,
      audit_log,
      pdf_ann,
      pdf_edt,
      share_wit,
      hw_tkn,
      nas_syn,
      vip_sup,
      inv_usr,
      del_usr,
      activ_dev,
      pdf_sig,
    } = params;
    const { plan_features: tempFeatures, pricing_plans } = plansData;
    const fullPlan = pricing_plans.filter((p: any) => p.plan_id === plan_id)[0];
    const idx = pricing_plans.indexOf(fullPlan) + 1 || 2;
    const valueId = `plan_${idx}_value`;
    const access_dny_feat = getIndexesOfFeature(tempFeatures, 'access_dny');
    if (access_dny) {
      if (access_dny_feat[2] > -1) {
        tempFeatures[access_dny_feat[0]].details[access_dny_feat[1]].details[
          access_dny_feat[2]
        ][valueId] = access_dny;
      } else {
        tempFeatures[access_dny_feat[0]].details[access_dny_feat[1]][valueId] =
          access_dny;
      }
    }

    const prom_adm_feat = getIndexesOfFeature(tempFeatures, 'prom_adm');
    if (prom_adm) {
      if (prom_adm_feat[2] > -1) {
        tempFeatures[prom_adm_feat[0]].details[prom_adm_feat[1]].details[
          prom_adm_feat[2]
        ][valueId] = prom_adm;
      } else {
        tempFeatures[prom_adm_feat[0]].details[prom_adm_feat[1]][valueId] =
          prom_adm;
      }
    }

    const block_usr_feat = getIndexesOfFeature(tempFeatures, 'block_usr');
    if (block_usr) {
      if (block_usr_feat[2] > -1) {
        tempFeatures[block_usr_feat[0]].details[block_usr_feat[1]].details[
          block_usr_feat[2]
        ][valueId] = block_usr;
      } else {
        tempFeatures[block_usr_feat[0]].details[block_usr_feat[1]][valueId] =
          block_usr;
      }
    }

    const power_usr_feat = getIndexesOfFeature(tempFeatures, 'power_usr');

    if (power_usr) {
      if (power_usr_feat[2] > -1) {
        tempFeatures[power_usr_feat[0]].details[power_usr_feat[1]].details[
          power_usr_feat[2]
        ][valueId] = power_usr;
      } else {
        tempFeatures[power_usr_feat[0]].details[power_usr_feat[1]][valueId] =
          power_usr;
      }
    }

    const guest_usr_feat = getIndexesOfFeature(tempFeatures, 'guest_usr');
    if (guest_usr) {
      if (guest_usr_feat[2] > -1) {
        tempFeatures[guest_usr_feat[0]].details[guest_usr_feat[1]].details[
          guest_usr_feat[2]
        ][valueId] = guest_usr;
      } else {
        tempFeatures[guest_usr_feat[0]].details[guest_usr_feat[1]][valueId] =
          guest_usr;
      }
    }

    const allow_dev_feat = getIndexesOfFeature(tempFeatures, 'allow_dev');
    if (allow_dev) {
      if (allow_dev_feat[2] > -1) {
        tempFeatures[allow_dev_feat[0]].details[allow_dev_feat[1]].details[
          allow_dev_feat[2]
        ][valueId] = allow_dev;
      } else {
        tempFeatures[allow_dev_feat[0]].details[allow_dev_feat[1]][valueId] =
          allow_dev;
      }
    }

    const paus_syn_feat = getIndexesOfFeature(tempFeatures, 'paus_syn');
    if (paus_syn) {
      if (paus_syn_feat[2] > -1) {
        tempFeatures[paus_syn_feat[0]].details[paus_syn_feat[1]].details[
          paus_syn_feat[2]
        ][valueId] = paus_syn;
      } else {
        tempFeatures[paus_syn_feat[0]].details[paus_syn_feat[1]][valueId] =
          paus_syn;
      }
    }

    const wipe_rmt_feat = getIndexesOfFeature(tempFeatures, 'wipe_rmt');
    if (wipe_rmt) {
      if (wipe_rmt_feat[2] > -1) {
        tempFeatures[wipe_rmt_feat[0]].details[wipe_rmt_feat[1]].details[
          wipe_rmt_feat[2]
        ][valueId] = wipe_rmt;
      } else {
        tempFeatures[wipe_rmt_feat[0]].details[wipe_rmt_feat[1]][valueId] =
          wipe_rmt;
      }
    }

    const perm_itm_feat = getIndexesOfFeature(tempFeatures, 'perm_itm');
    if (perm_itm) {
      if (perm_itm_feat[2] > -1) {
        tempFeatures[perm_itm_feat[0]].details[perm_itm_feat[1]].details[
          perm_itm_feat[2]
        ][valueId] = perm_itm;
      } else {
        tempFeatures[perm_itm_feat[0]].details[perm_itm_feat[1]][valueId] =
          perm_itm;
      }
    }

    const keep_rmt_feat = getIndexesOfFeature(tempFeatures, 'keep_rmt');
    if (keep_rmt) {
      if (keep_rmt_feat[2] > -1) {
        tempFeatures[keep_rmt_feat[0]].details[keep_rmt_feat[1]].details[
          keep_rmt_feat[2]
        ][valueId] = keep_rmt;
      } else {
        tempFeatures[keep_rmt_feat[0]].details[keep_rmt_feat[1]][valueId] =
          keep_rmt;
      }
    }

    const lock_key_feat = getIndexesOfFeature(tempFeatures, 'lock_key');
    if (lock_key) {
      if (lock_key_feat[2] > -1) {
        tempFeatures[lock_key_feat[0]].details[lock_key_feat[1]].details[
          lock_key_feat[2]
        ][valueId] = lock_key;
      } else {
        tempFeatures[lock_key_feat[0]].details[lock_key_feat[1]][valueId] =
          lock_key;
      }
    }

    const audit_log_feat = getIndexesOfFeature(tempFeatures, 'audit_log');
    if (audit_log) {
      if (audit_log_feat[2] > -1) {
        tempFeatures[audit_log_feat[0]].details[audit_log_feat[1]].details[
          audit_log_feat[2]
        ][valueId] = audit_log;
      } else {
        tempFeatures[audit_log_feat[0]].details[audit_log_feat[1]][valueId] =
          audit_log;
      }
    }

    const pdf_ann_feat = getIndexesOfFeature(tempFeatures, 'pdf_ann');
    if (pdf_ann) {
      if (pdf_ann_feat[2] > -1) {
        tempFeatures[pdf_ann_feat[0]].details[pdf_ann_feat[1]].details[
          pdf_ann_feat[2]
        ][valueId] = pdf_ann;
      } else {
        tempFeatures[pdf_ann_feat[0]].details[pdf_ann_feat[1]][valueId] =
          pdf_ann;
      }
    }

    const pdf_edt_feat = getIndexesOfFeature(tempFeatures, 'pdf_edt');
    if (pdf_edt) {
      if (pdf_edt_feat[2] > -1) {
        tempFeatures[pdf_edt_feat[0]].details[pdf_edt_feat[1]].details[
          pdf_edt_feat[2]
        ][valueId] = pdf_edt;
      } else {
        tempFeatures[pdf_edt_feat[0]].details[pdf_edt_feat[1]][valueId] =
          pdf_edt;
      }
    }

    const share_wit_feat = getIndexesOfFeature(tempFeatures, 'share_wit');
    if (share_wit) {
      if (share_wit_feat[2] > -1) {
        tempFeatures[share_wit_feat[0]].details[share_wit_feat[1]].details[
          share_wit_feat[2]
        ][valueId] = share_wit;
      } else {
        tempFeatures[share_wit_feat[0]].details[share_wit_feat[1]][valueId] =
          share_wit;
      }
    }

    const hw_tkn_feat = getIndexesOfFeature(tempFeatures, 'hw_tkn');
    if (hw_tkn) {
      if (hw_tkn_feat[2] > -1) {
        tempFeatures[hw_tkn_feat[0]].details[hw_tkn_feat[1]].details[
          hw_tkn_feat[2]
        ][valueId] = hw_tkn;
      } else {
        tempFeatures[hw_tkn_feat[0]].details[hw_tkn_feat[1]][valueId] = hw_tkn;
      }
    }

    const nas_syn_feat = getIndexesOfFeature(tempFeatures, 'nas_syn');
    if (nas_syn) {
      if (nas_syn_feat[2] > -1) {
        tempFeatures[nas_syn_feat[0]].details[nas_syn_feat[1]].details[
          nas_syn_feat[2]
        ][valueId] = nas_syn;
      } else {
        tempFeatures[nas_syn_feat[0]].details[nas_syn_feat[1]][valueId] =
          nas_syn;
      }
    }

    const vip_sup_feat = getIndexesOfFeature(tempFeatures, 'vip_sup');
    if (vip_sup) {
      if (vip_sup_feat[2] > -1) {
        tempFeatures[vip_sup_feat[0]].details[vip_sup_feat[1]].details[
          vip_sup_feat[2]
        ][valueId] = vip_sup;
      } else {
        tempFeatures[vip_sup_feat[0]].details[vip_sup_feat[1]][valueId] =
          vip_sup;
      }
    }

    const inv_usr_feat = getIndexesOfFeature(tempFeatures, 'inv_usr');
    if (inv_usr) {
      if (inv_usr_feat[2] > -1) {
        tempFeatures[inv_usr_feat[0]].details[inv_usr_feat[1]].details[
          inv_usr_feat[2]
        ][valueId] = inv_usr;
      } else {
        tempFeatures[inv_usr_feat[0]].details[inv_usr_feat[1]][valueId] =
          inv_usr;
      }
    }

    const del_usr_feat = getIndexesOfFeature(tempFeatures, 'del_usr');
    if (del_usr) {
      if (del_usr_feat[2] > -1) {
        tempFeatures[del_usr_feat[0]].details[del_usr_feat[1]].details[
          del_usr_feat[2]
        ][valueId] = del_usr;
      } else {
        tempFeatures[del_usr_feat[0]].details[del_usr_feat[1]][valueId] =
          del_usr;
      }
    }

    const activ_dev_feat = getIndexesOfFeature(tempFeatures, 'activ_dev');
    if (activ_dev) {
      if (activ_dev_feat[2] > -1) {
        tempFeatures[activ_dev_feat[0]].details[activ_dev_feat[1]].details[
          activ_dev_feat[2]
        ][valueId] = activ_dev;
      } else {
        tempFeatures[activ_dev_feat[0]].details[activ_dev_feat[1]][valueId] =
          activ_dev;
      }
    }

    const pdf_sig_feat = getIndexesOfFeature(tempFeatures, 'pdf_sig');
    if (pdf_sig) {
      if (pdf_sig_feat[2] > -1) {
        tempFeatures[pdf_sig_feat[0]].details[pdf_sig_feat[1]].details[
          pdf_sig_feat[2]
        ][valueId] = pdf_sig;
      } else {
        tempFeatures[pdf_sig_feat[0]].details[pdf_sig_feat[1]][valueId] =
          pdf_sig;
      }
    }
    const toPayload = getPlanPayloadDetails(tempFeatures, idx, recursion);
    return { tempFeatures, toPayload, idx, params };
  } catch (err) {
    return {};
  }
};

export const checkIfPlanIsCustom = (features: any, index: any) => {
  const valueKey = `plan_${index}_value`;
  const editableKey = `is_editable_for_plan_${index}`;
  let isCustom = false;
  if (!features?.length) {
    return isCustom;
  }

  const allFeatures = [
    getFeatureById(features, 'access_dny'),
    getFeatureById(features, 'prom_adm'),
    getFeatureById(features, 'block_usr'),
    getFeatureById(features, 'power_usr'),
    getFeatureById(features, 'guest_usr'),
    getFeatureById(features, 'allow_dev'),
    getFeatureById(features, 'paus_syn'),
    getFeatureById(features, 'wipe_rmt'),
    getFeatureById(features, 'perm_itm'),
    getFeatureById(features, 'keep_rmt'),
    getFeatureById(features, 'lock_key'),
    getFeatureById(features, 'audit_log'),
    getFeatureById(features, 'pdf_ann'),
    getFeatureById(features, 'pdf_edt'),
    getFeatureById(features, 'share_wit'),
    getFeatureById(features, 'hw_tkn'),
    getFeatureById(features, 'nas_syn'),
    getFeatureById(features, 'vip_sup'),
  ];
  allFeatures.map((feature) => {
    const isEditable = feature[editableKey];
    const value = feature[valueKey];
    const isTrue = isValueTrue(value);

    if (isEditable && isTrue) {
      isCustom = true;
    }
  });
  return isCustom;
};

export const addLangToHtml = () => {
  try {
    const availableLanguages = [
      {
        language_code: 'fr',
      },
      {
        language_code: 'de',
      },
      {
        language_code: 'es',
      },
      {
        language_code: 'it',
      },
      {
        language_code: 'ru',
      },
      {
        language_code: 'pt',
      },
      {
        language_code: 'en',
      },
    ];

    const currentURL = window.location.pathname;
    const currentURLWithoutSearchParamsAndWithoutHash = currentURL
      .split('?')[0]
      .split('#')[0];
    const lang = availableLanguages.find((item) =>
      currentURLWithoutSearchParamsAndWithoutHash.includes(item.language_code),
    );
    if (lang) {
      document.documentElement.lang = lang.language_code;
    } else {
      document.documentElement.lang = 'en';
    }
  } catch (err) {
    // DO NOTHING
  }
};

const flatten: any = (arr: any[]) =>
  arr.reduce(
    (acc, val) =>
      Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val),
    [],
  );

export function encodeString(originalString: string, features: IPlanFeature[]) {
  let flattenFeatures = flatten(
    features.map((feature) => feature?.details || []),
  ) as IPlanFeatureDetail[];
  flattenFeatures = flatten(
    flattenFeatures.map((feature) =>
      feature?.details?.length ? feature?.details : feature,
    ),
  );

  return originalString
    .split(',')
    .map((item) => {
      const matchingItem = flattenFeatures.find(
        (featureItem) => featureItem.detail_id === item,
      );
      return matchingItem ? matchingItem.two_char_id : '';
    })
    .join(',');
}

export function add_a_prefix(s: string) {
  if (s.length < 24) {
    let numberOfA = 24 - s.length;
    s = 'A'.repeat(numberOfA) + s;
  }
  return s;
}
