import Logger from '@utils/logger/server';
import axios, { AxiosResponse } from 'axios';
import { LRUCache } from 'lru-cache';
import { NextApiRequest, NextApiResponse } from 'next';
import requestIp from 'request-ip';

export const getIP = (req: NextApiRequest) => {
  let ip = req.headers['x-real-ip'] as string;

  if (!ip) {
    const forwarded = req.headers['x-forwarded-for'];
    if (typeof forwarded === 'string') {
      ip = forwarded.split(/\s*,\s*/)[0];
    }
  }

  return ip || requestIp.getClientIp(req);
};

export const getUserAgent = (req: NextApiRequest) => req.headers['user-agent'];

export const getRequiredHeaders = (req: NextApiRequest) => {
  const ip = getIP(req);
  const userAgent = getUserAgent(req);
  return {
    'X-Forwarded-For': ip,
    X_FORWARDED_FOR_SMS: ip,
    'User-Agent': userAgent,
    HTTP_X_FORWARDED_FOR: ip,
    HTTP_X_FORWARDED_FOR_SMS: ip,
    X_FORWARDED_FOR_MATOMO: ip,
    X_FORWARDED_FOR_ANALYTICS: ip,
  };
};

export const logRequest = (req: NextApiRequest) => {
  Logger.logRequest(req);
};

export const error = (message: string, additionalInfo: any = {}) => {
  Logger.error(message, additionalInfo);
};

export const logResponse = (req: NextApiRequest, res: AxiosResponse<any>) => {
  Logger.logResponse(req, res);
};

export const log = (message: string, additionalInfo: any = {}) => {
  Logger.log(message, additionalInfo);
};

export const axiosWithRetry = async (
  axiosCall: () => Promise<AxiosResponse<any>>,
  retries: number,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await axiosCall();
    return response;
  } catch (err: any) {
    error('axios call error, auto retry if retry if retries > 0', {
      err: err?.response?.data || err?.message || err,
      retries,
    });
    if (retries === 0) throw err;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return axiosWithRetry(axiosCall, retries - 1);
  }
};

export const setupCache = (options?: LRUCache.Options<{}, {}, unknown>) => {
  let cache = new LRUCache({
    ...(options || {}),
    max: 1000,
    ttl: 2 * 60 * 60 * 1000,
  });
  return cache;
};

// eslint-disable-next-line no-unused-vars
export const getCache = (cache: LRUCache<{}, {}, unknown>, key: string) => '';
// process.env.NODE_ENV === 'development' ? '' : (cache.get(key) as string);

export const setCache = (
  cache: LRUCache<{}, {}, unknown>,
  key: string,
  value: string,
) => cache.set(key, value);

export const getFileTypeFromfileName = (fileName = '') => {
  const fileExtension = fileName.split('.').pop() || 'pdf';
  const fileTypes = {
    svg: 'image/svg+xml',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    webp: 'image/webp',
    gif: 'image/gif',
    bmp: 'image/bmp',
    tiff: 'image/tiff',
    mp4: 'video/mp4',
    webm: 'video/webm',
    ogg: 'video/ogg',
  };
  return (
    fileTypes[fileExtension as keyof typeof fileTypes] || 'application/pdf'
  );
};

export const fetchFileSever = async (tempURL: string) => {
  let url = tempURL as string;
  try {
    url = decodeURIComponent(tempURL);
  } catch (err) {
    url = tempURL || '';
  }

  if (!url) {
    return '';
  }

  try {
    const response = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    const base64 = Buffer.from(response.data, 'binary').toString('base64');
    const formattedBase64 = `data:${getFileTypeFromfileName(
      url,
    )};base64,${base64}`;
    return formattedBase64;
  } catch (err) {
    return '';
  }
};

export const fetchURLAndCovertResponseToBase64 = async (url: string) => {
  const response = await fetchFileSever(url);
  return response;
};

export const transformImage = async (url: string) => {
  const base64Response = await fetchURLAndCovertResponseToBase64(url);
  return base64Response;
};

export const preLoadImageServer = async (mediaURL: string) => {
  if (!mediaURL || mediaURL.startsWith('data:')) {
    return mediaURL || '';
  }
  try {
    const transformedMedia = await transformImage(mediaURL);
    return transformedMedia;
  } catch (err) {
    return mediaURL || '';
  }
};

export const handle503 = (
  res: NextApiResponse,
  err: any,
  fallback: () => void,
) => {
  if (err?.response?.status === 503) {
    return res.status(503).send('Service Unavailable');
  }
  return fallback();
};
